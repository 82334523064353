.sendbird__offline .sendbird-channel-preview {
  cursor: not-allowed; }

.sendbird-channel-preview {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  width: 320px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 16px 12px 16px; }
  .sendbird-theme--light .sendbird-channel-preview {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-channel-preview {
    border-bottom: solid 1px rgba(255, 255, 255, 0.12); }
  .sendbird-channel-preview .sendbird-channel-preview__frozen-icon {
    padding-left: 4px; }
  .sendbird-channel-preview .sendbird-channel-preview__broadcast-icon {
    padding-right: 4px; }
  .sendbird-channel-preview:focus {
    outline: none; }
  .sendbird-channel-preview .sendbird-channel-preview__avatar {
    width: 56px;
    height: 56px; }
  .sendbird-channel-preview .sendbird-channel-preview__content {
    width: 100%;
    height: 100%;
    margin-left: 16px; }
    .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper {
      display: flex;
      justify-content: space-between;
      width: 216px;
      height: 16px; }
      .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper__header {
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        text-overflow: ellipsis; }
        .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper__header__channel-name {
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 128px;
          word-break: break-all;
          white-space: nowrap; }
        .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper__header__total-members {
          margin-top: 2px;
          margin-left: 4px; }
      .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper__last-message-at {
        margin-left: 4px;
        margin-bottom: 4px;
        white-space: nowrap; }
    .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower {
      margin-top: 8px;
      width: 216px;
      height: 32px;
      display: flex;
      justify-content: space-between; }
      .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower__last-message {
        overflow: hidden;
        text-overflow: ellipsis; }
      .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower__unread-message-count {
        margin-left: 8px;
        margin-bottom: 12px; }
  .sendbird-channel-preview .sendbird-channel-preview__action {
    position: absolute;
    top: 12px;
    right: 12px;
    display: inline-block; }
    .sendbird-channel-preview .sendbird-channel-preview__action .sendbird-iconbutton {
      display: none; }
  .sendbird-theme--light .sendbird-channel-preview:hover {
    background-color: #F0F0F0; }
  .sendbird-theme--dark .sendbird-channel-preview:hover {
    background-color: #2C2C2C; }
  .sendbird-channel-preview:hover .sendbird-channel-preview__action .sendbird-iconbutton {
    display: inline-block; }
  .sendbird-channel-preview:hover .sendbird-channel-preview__content__upper__last-message-at {
    display: none; }
  .sendbird-channel-preview:hover .sendbird-channel-preview__content__lower__unread-message-count {
    display: none; }

.sendbird-channel-preview--active {
  padding: 12px 16px 12px 12px; }
  .sendbird-theme--light .sendbird-channel-preview--active {
    border-left: solid 4px #7B53EF;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #E2DFFF; }
  .sendbird-theme--dark .sendbird-channel-preview--active {
    border-left: solid 4px #9E8CF5;
    border-bottom: solid 1px rgba(255, 255, 255, 0.12);
    background-color: #000000; }
  .sendbird-theme--light .sendbird-channel-preview--active .sendbird-channel-preview__content__upper__header__channel-name {
    color: #7B53EF; }
  .sendbird-theme--dark .sendbird-channel-preview--active .sendbird-channel-preview__content__upper__header__channel-name {
    color: #9E8CF5; }

.sendbird-image-renderer--hidden-placeholder {
  display: none; }

.sendbird-avatar {
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  position: relative; }
  .sendbird-avatar:focus {
    outline: none; }
  .sendbird-avatar .sendbird-avatar-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .sendbird-avatar .sendbird-avatar--inner__two-child .sendbird-avatar-img:first-child {
    transform: translate(2%, -50%); }
  .sendbird-avatar .sendbird-avatar--inner__two-child .sendbird-avatar-img:last-child {
    transform: translate(-102%, -50%); }
  .sendbird-avatar .sendbird-avatar--inner__three-child--upper .sendbird-avatar-img:first-child {
    transform: translate(-50%, -102%); }
  .sendbird-avatar .sendbird-avatar--inner__three-child--lower .sendbird-avatar-img:first-child {
    transform: translate(-77%, -23%) scale(0.5); }
  .sendbird-avatar .sendbird-avatar--inner__three-child--lower .sendbird-avatar-img:last-child {
    transform: translate(-23%, -23%) scale(0.5); }
  .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:first-child {
    transform: translate(-77%, -77%) scale(0.5); }
  .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:nth-child(2) {
    transform: translate(-23%, -77%) scale(0.5); }
  .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:nth-child(3) {
    transform: translate(-77%, -23%) scale(0.5); }
  .sendbird-avatar .sendbird-avatar--inner__four-child .sendbird-avatar-img:last-child {
    transform: translate(-23%, -23%) scale(0.5); }

.sendbird-icon {
  display: inline-block; }
  .sendbird-icon:focus {
    outline: none; }

.sendbird-theme--light .sendbird-color--primary [class*='fill'] {
  fill: #7B53EF; }

.sendbird-theme--dark .sendbird-color--primary [class*='fill'] {
  fill: #9E8CF5; }

.sendbird-theme--light .sendbird-color--secondary [class*='fill'] {
  fill: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-color--secondary [class*='fill'] {
  fill: rgba(255, 255, 255, 0.88); }

.sendbird-theme--light .sendbird-color--content [class*='fill'] {
  fill: rgba(255, 255, 255, 0.88); }

.sendbird-theme--dark .sendbird-color--content [class*='fill'] {
  fill: rgba(0, 0, 0, 0.88); }

.sendbird-theme--light .sendbird-color--content-inverse [class*='fill'] {
  fill: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-color--content-inverse [class*='fill'] {
  fill: rgba(255, 255, 255, 0.88); }

.sendbird-color--white [class*='fill'] {
  fill: #ffffff; }

.sendbird-theme--light .sendbird-color--sent [class*='fill'] {
  fill: rgba(0, 0, 0, 0.38); }

.sendbird-theme--dark .sendbird-color--sent [class*='fill'] {
  fill: rgba(255, 255, 255, 0.38); }

.sendbird-theme--light .sendbird-color--read [class*='fill'] {
  fill: #2EBA9F; }

.sendbird-theme--dark .sendbird-color--read [class*='fill'] {
  fill: #6FD6BE; }

.sendbird-theme--light .sendbird-color--on-background [class*='fill'] {
  fill: rgba(0, 0, 0, 0.38); }

.sendbird-theme--dark .sendbird-color--on-background [class*='fill'] {
  fill: rgba(255, 255, 255, 0.38); }

.sendbird-badge {
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  display: inline-block; }
  .sendbird-theme--light .sendbird-badge {
    background-color: #7B53EF; }
  .sendbird-theme--dark .sendbird-badge {
    background-color: #9E8CF5; }
  .sendbird-badge .sendbird-badge__text {
    margin: 0px 6px; }

[class*=sendbird-label] {
  font-family: "Open Sans", sans-serif; }

.sendbird-label--h-1 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.2px; }

.sendbird-label--h-2 {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px; }

.sendbird-label--subtitle-1 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.2px; }

.sendbird-label--subtitle-2 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.2px; }

.sendbird-label--body-1 {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal; }

.sendbird-label--body-2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal; }

.sendbird-label--button-1 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal; }

.sendbird-label--button-2 {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal; }

.sendbird-label--caption-1 {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal; }

.sendbird-label--caption-2 {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal; }

.sendbird-label--caption-3 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal; }

.sendbird-theme--light .sendbird-label--color-onbackground-1 {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-label--color-onbackground-1 {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--light .sendbird-label--color-onbackground-2 {
  color: rgba(0, 0, 0, 0.5); }

.sendbird-theme--dark .sendbird-label--color-onbackground-2 {
  color: rgba(255, 255, 255, 0.5); }

.sendbird-theme--light .sendbird-label--color-onbackground-3 {
  color: rgba(0, 0, 0, 0.38); }

.sendbird-theme--dark .sendbird-label--color-onbackground-3 {
  color: rgba(255, 255, 255, 0.38); }

.sendbird-theme--light .sendbird-label--color-oncontent-1 {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--dark .sendbird-label--color-oncontent-1 {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--light .sendbird-label--color-oncontent-2 {
  color: rgba(255, 255, 255, 0.5); }

.sendbird-theme--dark .sendbird-label--color-oncontent-2 {
  color: rgba(0, 0, 0, 0.5); }

.sendbird-theme--light .sendbird-label--color-primary {
  color: #7B53EF; }

.sendbird-theme--dark .sendbird-label--color-primary {
  color: #9E8CF5; }

.sendbird-theme--light .sendbird-label--color-error {
  color: #E53157; }

.sendbird-theme--dark .sendbird-label--color-error {
  color: #E53157; }

.sendbird-iconbutton {
  color: #825eeb;
  display: inline-block;
  padding: 3px;
  background-color: transparent;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: 0px; }
  .sendbird-theme--light .sendbird-iconbutton .sendbird-iconbutton__inner svg {
    fill: rgba(0, 0, 0, 0.88); }
  .sendbird-theme--dark .sendbird-iconbutton .sendbird-iconbutton__inner svg {
    fill: rgba(255, 255, 255, 0.88); }
  .sendbird-theme--light .sendbird-iconbutton:hover {
    background-color: #F0F0F0; }
  .sendbird-theme--dark .sendbird-iconbutton:hover {
    background-color: #2C2C2C; }
  .sendbird-theme--light .sendbird-iconbutton:focus {
    box-shadow: #7B53EF 0px 0px 0px 2px; }
  .sendbird-theme--dark .sendbird-iconbutton:focus {
    box-shadow: #9E8CF5 0px 0px 0px 2px; }
  .sendbird-theme--light .sendbird-iconbutton:active {
    background-color: #D9D9D9; }
  .sendbird-theme--dark .sendbird-iconbutton:active {
    background-color: #393939; }
  .sendbird-iconbutton:disabled {
    cursor: not-allowed !important; }
    .sendbird-theme--light .sendbird-iconbutton:disabled .sendbird-iconbutton__inner svg, .sendbird-theme--light .sendbird-iconbutton:disabled .sendbird-iconbutton__inner path {
      fill: rgba(0, 0, 0, 0.5); }
    .sendbird-theme--dark .sendbird-iconbutton:disabled .sendbird-iconbutton__inner svg, .sendbird-theme--dark .sendbird-iconbutton:disabled .sendbird-iconbutton__inner path {
      fill: rgba(255, 255, 255, 0.5); }

.sendbird-theme--light .sendbird-iconbutton--pressed {
  background-color: #E2DFFF; }

.sendbird-theme--dark .sendbird-iconbutton--pressed {
  background-color: #000000; }

.sendbird-channel-header {
  position: relative;
  height: 64px;
  min-height: 64px;
  width: 320px;
  padding: 8px 64px 8px 8px;
  box-sizing: border-box; }
  .sendbird-theme--light .sendbird-channel-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-channel-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #161616; }
  .sendbird-channel-header.sendbird-channel-header--allow-edit .sendbird-channel-header__title {
    cursor: pointer; }
  .sendbird-channel-header .sendbird-channel-header__title {
    display: flex;
    flex-direction: row;
    width: 260px;
    height: 48px;
    border-radius: 4px; }
    .sendbird-theme--light .sendbird-channel-header .sendbird-channel-header__title:hover {
      background: #F0F0F0; }
    .sendbird-theme--dark .sendbird-channel-header .sendbird-channel-header__title:hover {
      background: #2C2C2C; }
    .sendbird-channel-header .sendbird-channel-header__title:focus {
      outline: none; }
      .sendbird-theme--light .sendbird-channel-header .sendbird-channel-header__title:focus {
        background: #D9D9D9; }
      .sendbird-theme--dark .sendbird-channel-header .sendbird-channel-header__title:focus {
        background: #393939; }
    .sendbird-theme--light .sendbird-channel-header .sendbird-channel-header__title:active {
      border: solid 2px #7B53EF; }
    .sendbird-theme--dark .sendbird-channel-header .sendbird-channel-header__title:active {
      border: solid 2px #9E8CF5; }
    .sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title--left {
      width: 48px;
      padding: 8px;
      box-sizing: border-box; }
    .sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title--right {
      margin-left: 4px;
      padding-top: 8px; }
    .sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title--name,
    .sendbird-channel-header .sendbird-channel-header__title .sendbird-channel-header__title--user-id {
      display: block;
      max-width: 200px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis; }
  .sendbird-channel-header .sendbird-channel-header__right-icon {
    position: absolute;
    right: 16px;
    top: 16px; }

.sendbird__edit-user-profile section {
  margin-top: 16px; }
  .sendbird__edit-user-profile section .sendbird-input-label {
    display: block;
    margin-bottom: 12px; }

.sendbird__edit-user-profile .sendbird__edit-user-profile__img .sendbird-input-label {
  margin-bottom: 12px; }

.sendbird__edit-user-profile__img {
  position: relative; }

.sendbird__edit-user__avatar-button {
  position: absolute;
  top: 50px;
  left: 96px; }

.sendbird__edit-user__theme-icon .sendbird-icon {
  cursor: pointer; }

.sendbird-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.sendbird-modal-content {
  width: 480px;
  border-radius: 4px;
  z-index: 10001;
  padding: 18px 24px 24px 24px;
  position: relative;
  box-sizing: border-box; }
  .sendbird-theme--light .sendbird-modal-content {
    background-color: #FFFFFF;
    box-shadow: 0 9px 15px -7px rgba(0, 0, 0, 0.04), 0 9px 46px 8px rgba(0, 0, 0, 0.08), 0 24px 38px 3px rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-modal-content {
    background-color: #2C2C2C;
    box-shadow: 0 9px 15px -7px rgba(0, 0, 0, 0.04), 0 9px 46px 8px rgba(0, 0, 0, 0.08), 0 24px 38px 3px rgba(0, 0, 0, 0.12); }
  .sendbird-modal-content .sendbird-modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer; }
    .sendbird-theme--light .sendbird-modal-content .sendbird-modal-close path {
      fill: rgba(0, 0, 0, 0.88); }
    .sendbird-theme--dark .sendbird-modal-content .sendbird-modal-close path {
      fill: rgba(255, 255, 255, 0.88); }
  .sendbird-modal-content .sendbird-modal-header {
    margin-bottom: 16px; }
  .sendbird-modal-content .sendbird-modal-footer {
    margin-top: 32px;
    text-align: right; }
    .sendbird-modal-content .sendbird-modal-footer .sendbird-button:last-child {
      margin-left: 8px;
      width: 80px;
      height: 40px; }

.sendbird-modal-backdrop {
  position: fixed;
  z-index: 10000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; }
  .sendbird-theme--light .sendbird-modal-backdrop {
    background-color: rgba(0, 0, 0, 0.32); }
  .sendbird-theme--dark .sendbird-modal-backdrop {
    background-color: rgba(0, 0, 0, 0.32); }

.sendbird-button {
  display: inline-block;
  box-shadow: none;
  border-radius: 4px;
  padding: 0px;
  font-family: "Open Sans", sans-serif;
  cursor: pointer; }
  .sendbird-button:hover {
    box-shadow: none; }
  .sendbird-button:focus {
    outline: none; }
  .sendbird-button:active {
    box-shadow: none; }
  .sendbird-button.sendbird-button__disabled {
    cursor: not-allowed; }
    .sendbird-theme--light .sendbird-button.sendbird-button__disabled {
      background-color: #D9D9D9; }
    .sendbird-theme--dark .sendbird-button.sendbird-button__disabled {
      background-color: #393939; }
    .sendbird-theme--light .sendbird-button.sendbird-button__disabled:hover {
      background-color: #D9D9D9; }
    .sendbird-theme--dark .sendbird-button.sendbird-button__disabled:hover {
      background-color: #393939; }

.sendbird-button--big {
  height: 40px; }
  .sendbird-button--big .sendbird-button__text {
    margin: 10px 16px; }

.sendbird-button--small {
  height: 32px; }
  .sendbird-button--small .sendbird-button__text {
    margin: 6px 16px; }

.sendbird-theme--light .sendbird-button--primary {
  border: 1px solid #7B53EF;
  background-color: #7B53EF; }

.sendbird-theme--dark .sendbird-button--primary {
  border: 1px solid #9E8CF5;
  background-color: #9E8CF5; }

.sendbird-theme--light .sendbird-button--primary .sendbird-button__text {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--dark .sendbird-button--primary .sendbird-button__text {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--light .sendbird-button--primary:hover {
  background-color: #6440C4;
  border-color: #6440C4; }

.sendbird-theme--dark .sendbird-button--primary:hover {
  background-color: #7B53EF;
  border-color: #7B53EF; }

.sendbird-theme--light .sendbird-button--primary:focus {
  box-shadow: 0px 0px 0px 2px #7B53EF;
  border: 1px solid #FFFFFF; }

.sendbird-theme--dark .sendbird-button--primary:focus {
  box-shadow: 0px 0px 0px 2px #9E8CF5;
  border: 1px solid #FFFFFF; }

.sendbird-theme--light .sendbird-button--primary:active {
  background-color: #4d2aa6; }

.sendbird-theme--dark .sendbird-button--primary:active {
  background-color: #6440C4; }

.sendbird-theme--light .sendbird-button--primary:focus .sendbird-button--primary:hover {
  box-shadow: 0px 0px 0px 2px #6440C4; }

.sendbird-theme--dark .sendbird-button--primary:focus .sendbird-button--primary:hover {
  box-shadow: 0px 0px 0px 2px #7B53EF; }

.sendbird-theme--light .sendbird-button--primary:focus .sendbird-button--primary:active {
  box-shadow: 0px 0px 0px 2px #4d2aa6; }

.sendbird-theme--dark .sendbird-button--primary:focus .sendbird-button--primary:active {
  box-shadow: 0px 0px 0px 2px #6440C4; }

.sendbird-button--secondary {
  background-color: transparent; }
  .sendbird-theme--light .sendbird-button--secondary {
    border: 1px solid rgba(0, 0, 0, 0.38); }
  .sendbird-theme--dark .sendbird-button--secondary {
    border: 1px solid rgba(255, 255, 255, 0.38); }
  .sendbird-theme--light .sendbird-button--secondary .sendbird-button__text {
    color: rgba(0, 0, 0, 0.88); }
  .sendbird-theme--dark .sendbird-button--secondary .sendbird-button__text {
    color: rgba(255, 255, 255, 0.88); }
  .sendbird-button--secondary:hover {
    background-color: transparent; }
    .sendbird-theme--light .sendbird-button--secondary:hover {
      border: 1px solid #7B53EF; }
    .sendbird-theme--dark .sendbird-button--secondary:hover {
      border: 1px solid #9E8CF5; }
    .sendbird-theme--light .sendbird-button--secondary:hover .sendbird-button__text {
      color: #7B53EF; }
    .sendbird-theme--dark .sendbird-button--secondary:hover .sendbird-button__text {
      color: #9E8CF5; }
  .sendbird-button--secondary.sendbird-button__disabled {
    cursor: not-allowed;
    border: none; }
    .sendbird-theme--light .sendbird-button--secondary.sendbird-button__disabled {
      background-color: #D9D9D9; }
    .sendbird-theme--dark .sendbird-button--secondary.sendbird-button__disabled {
      background-color: #393939; }
    .sendbird-theme--light .sendbird-button--secondary.sendbird-button__disabled .sendbird-button__text {
      color: rgba(0, 0, 0, 0.5); }
    .sendbird-theme--dark .sendbird-button--secondary.sendbird-button__disabled .sendbird-button__text {
      color: rgba(255, 255, 255, 0.5); }
    .sendbird-button--secondary.sendbird-button__disabled:hover {
      border: none; }
      .sendbird-theme--light .sendbird-button--secondary.sendbird-button__disabled:hover {
        background-color: #D9D9D9; }
      .sendbird-theme--dark .sendbird-button--secondary.sendbird-button__disabled:hover {
        background-color: #393939; }
      .sendbird-theme--light .sendbird-button--secondary.sendbird-button__disabled:hover .sendbird-button__text {
        color: rgba(0, 0, 0, 0.5); }
      .sendbird-theme--dark .sendbird-button--secondary.sendbird-button__disabled:hover .sendbird-button__text {
        color: rgba(255, 255, 255, 0.5); }
  .sendbird-theme--light .sendbird-button--secondary:hover .sendbird-button--secondary:focus .sendbird-button__text {
    color: #7B53EF; }
  .sendbird-theme--dark .sendbird-button--secondary:hover .sendbird-button--secondary:focus .sendbird-button__text {
    color: #9E8CF5; }
  .sendbird-button--secondary:focus {
    border: 1px solid transparent;
    background-color: transparent; }
    .sendbird-theme--light .sendbird-button--secondary:focus {
      box-shadow: #7B53EF 0px 0px 0px 2px; }
    .sendbird-theme--dark .sendbird-button--secondary:focus {
      box-shadow: #9E8CF5 0px 0px 0px 2px; }
    .sendbird-theme--light .sendbird-button--secondary:focus .sendbird-button__text {
      color: rgba(0, 0, 0, 0.88); }
    .sendbird-theme--dark .sendbird-button--secondary:focus .sendbird-button__text {
      color: rgba(255, 255, 255, 0.88); }
  .sendbird-button--secondary:active {
    box-shadow: none; }
    .sendbird-theme--light .sendbird-button--secondary:active {
      border: 1px solid #7B53EF; }
    .sendbird-theme--dark .sendbird-button--secondary:active {
      border: 1px solid #9E8CF5; }
    .sendbird-theme--light .sendbird-button--secondary:active .sendbird-button__text {
      color: #7B53EF; }
    .sendbird-theme--dark .sendbird-button--secondary:active .sendbird-button__text {
      color: #9E8CF5; }

.sendbird-theme--light .sendbird-button--danger {
  border: 1px solid #E53157;
  background-color: #E53157; }

.sendbird-theme--dark .sendbird-button--danger {
  border: 1px solid #E53157;
  background-color: #E53157; }

.sendbird-theme--light .sendbird-button--danger .sendbird-button__text {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--dark .sendbird-button--danger .sendbird-button__text {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--light .sendbird-button--danger:hover {
  background-color: #C11F41;
  border-color: #C11F41; }

.sendbird-theme--dark .sendbird-button--danger:hover {
  background-color: #C11F41;
  border-color: #C11F41; }

.sendbird-theme--light .sendbird-button--danger:focus {
  box-shadow: 0px 0px 0px 2px #E53157;
  border-color: #FFFFFF; }

.sendbird-theme--dark .sendbird-button--danger:focus {
  box-shadow: 0px 0px 0px 2px #E53157;
  border-color: #FFFFFF; }

.sendbird-button--danger:active {
  box-shadow: none; }
  .sendbird-theme--light .sendbird-button--danger:active {
    background-color: #A30E2D; }
  .sendbird-theme--dark .sendbird-button--danger:active {
    background-color: #A30E2D; }

.sendbird-theme--light .sendbird-button--danger:hover .sendbird-button--danger:focus {
  box-shadow: 0px 0px 0px 2px #C11F41; }

.sendbird-theme--dark .sendbird-button--danger:hover .sendbird-button--danger:focus {
  box-shadow: 0px 0px 0px 2px #C11F41; }

.sendbird-theme--light .sendbird-button--danger:active .sendbird-button--danger:focus {
  box-shadow: 0px 0px 0px 2px #A30E2D; }

.sendbird-theme--dark .sendbird-button--danger:active .sendbird-button--danger:focus {
  box-shadow: 0px 0px 0px 2px #A30E2D; }

.sendbird-button--disabled {
  border: 1px solid transparent;
  cursor: not-allowed; }
  .sendbird-theme--light .sendbird-button--disabled {
    background-color: #D9D9D9;
    color: rgba(255, 255, 255, 0.88); }
  .sendbird-theme--dark .sendbird-button--disabled {
    background-color: #393939;
    color: rgba(0, 0, 0, 0.88); }

.sendbird-input {
  display: inline-block;
  width: 100%;
  height: 40px;
  position: relative; }
  .sendbird-input .sendbird-input--input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    padding: 10px 16px;
    box-sizing: border-box;
    background-color: inherit; }
    .sendbird-theme--light .sendbird-input .sendbird-input--input {
      color: rgba(0, 0, 0, 0.88);
      caret-color: #7B53EF;
      border: solid 1px rgba(0, 0, 0, 0.38); }
    .sendbird-theme--dark .sendbird-input .sendbird-input--input {
      color: rgba(255, 255, 255, 0.88);
      caret-color: #9E8CF5;
      border: solid 1px rgba(255, 255, 255, 0.38); }
    .sendbird-input .sendbird-input--input:focus {
      outline: none;
      box-shadow: 0 1px 5px 0 var(--onlight-04), 0 0 1px 0 rgba(0, 0, 0, 0.16), 0 2px 1px 0 rgba(0, 0, 0, 0.08); }
      .sendbird-theme--light .sendbird-input .sendbird-input--input:focus {
        color: rgba(0, 0, 0, 0.88);
        border: solid 2px #7B53EF; }
      .sendbird-theme--dark .sendbird-input .sendbird-input--input:focus {
        color: rgba(255, 255, 255, 0.88);
        border: solid 2px #9E8CF5; }
    .sendbird-input .sendbird-input--input:active {
      box-shadow: 0 1px 5px 0 var(--onlight-04), 0 0 1px 0 rgba(0, 0, 0, 0.16), 0 2px 1px 0 rgba(0, 0, 0, 0.08); }
      .sendbird-theme--light .sendbird-input .sendbird-input--input:active {
        color: rgba(0, 0, 0, 0.88);
        border: solid 1px #7B53EF; }
      .sendbird-theme--dark .sendbird-input .sendbird-input--input:active {
        color: rgba(255, 255, 255, 0.88);
        border: solid 1px #9E8CF5; }
    .sendbird-input .sendbird-input--input:disabled {
      cursor: not-allowed; }
      .sendbird-theme--light .sendbird-input .sendbird-input--input:disabled {
        background-color: #F0F0F0;
        color: rgba(0, 0, 0, 0.38); }
      .sendbird-theme--dark .sendbird-input .sendbird-input--input:disabled {
        background-color: #2C2C2C;
        color: rgba(255, 255, 255, 0.38); }
  .sendbird-input .sendbird-input--placeholder {
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 1.43; }
    .sendbird-theme--light .sendbird-input .sendbird-input--placeholder {
      color: rgba(0, 0, 0, 0.38); }
    .sendbird-theme--dark .sendbird-input .sendbird-input--placeholder {
      color: rgba(255, 255, 255, 0.38); }

.sendbird-input-label {
  margin: 4px 0; }

.sendbird-textbutton {
  text-decoration: underline;
  cursor: pointer; }

.sendbird-textbutton--not-underline {
  text-decoration: none;
  cursor: pointer; }

.sendbird-textbutton--disabled {
  cursor: not-allowed; }
  .sendbird-theme--light .sendbird-textbutton--disabled {
    color: rgba(0, 0, 0, 0.38); }
  .sendbird-theme--dark .sendbird-textbutton--disabled {
    color: rgba(255, 255, 255, 0.38); }

.sendbird-theme--light .sendbird-color--onbackground-1 {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-color--onbackground-1 {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--light .sendbird-color--onbackground-1--background-color {
  background-color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-color--onbackground-1--background-color {
  background-color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--light .sendbird-color--onbackground-2 {
  color: rgba(0, 0, 0, 0.5); }

.sendbird-theme--dark .sendbird-color--onbackground-2 {
  color: rgba(255, 255, 255, 0.5); }

.sendbird-theme--light .sendbird-color--onbackground-2--background-color {
  background-color: rgba(0, 0, 0, 0.5); }

.sendbird-theme--dark .sendbird-color--onbackground-2--background-color {
  background-color: rgba(255, 255, 255, 0.5); }

.sendbird-theme--light .sendbird-color--onbackground-3 {
  color: rgba(0, 0, 0, 0.38); }

.sendbird-theme--dark .sendbird-color--onbackground-3 {
  color: rgba(255, 255, 255, 0.38); }

.sendbird-theme--light .sendbird-color--onbackground-3--background-color {
  background-color: rgba(0, 0, 0, 0.38); }

.sendbird-theme--dark .sendbird-color--onbackground-3--background-color {
  background-color: rgba(255, 255, 255, 0.38); }

.sendbird-theme--light .sendbird-color--onbackground-4 {
  color: rgba(0, 0, 0, 0.12); }

.sendbird-theme--dark .sendbird-color--onbackground-4 {
  color: rgba(255, 255, 255, 0.12); }

.sendbird-theme--light .sendbird-color--onbackground-4--background-color {
  background-color: rgba(0, 0, 0, 0.12); }

.sendbird-theme--dark .sendbird-color--onbackground-4--background-color {
  background-color: rgba(255, 255, 255, 0.12); }

.sendbird-theme--light .sendbird-color--oncontent-1 {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--dark .sendbird-color--oncontent-1 {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--light .sendbird-color--oncontent-1--background-color {
  background-color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--dark .sendbird-color--oncontent-1--background-color {
  background-color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--light .sendbird-color--primary {
  color: #7B53EF; }

.sendbird-theme--dark .sendbird-color--primary {
  color: #9E8CF5; }

.sendbird-theme--light .sendbird-color--primary--background-color {
  background-color: #7B53EF; }

.sendbird-theme--dark .sendbird-color--primary--background-color {
  background-color: #9E8CF5; }

.sendbird-theme--light .sendbird-color--error {
  color: #E53157; }

.sendbird-theme--dark .sendbird-color--error {
  color: #E53157; }

.sendbird-theme--light .sendbird-color--error--background-color {
  background-color: #E53157; }

.sendbird-theme--dark .sendbird-color--error--background-color {
  background-color: #E53157; }

.sendbird-add-channel__rectangle-wrap {
  margin-top: 26px; }

.sendbird-add-channel__rectangle-footer {
  text-align: right;
  margin-top: 32px; }

.sendbird-add-channel__rectangle {
  width: 432px;
  height: 72px;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 25px 72px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer; }
  .sendbird-theme--light .sendbird-add-channel__rectangle {
    border: 1px solid rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-add-channel__rectangle {
    border: 1px solid rgba(255, 255, 255, 0.12); }
  .sendbird-add-channel__rectangle .sendbird-icon {
    position: absolute;
    left: 22px;
    top: 24px; }

.sendbird-create-channel--content {
  width: 480px;
  max-height: 552px; }

.sendbird-create-channel--scroll {
  height: 360px;
  overflow-y: auto; }

.sendbird-muted-avatar {
  border-radius: 50%;
  display: inline-block; }
  .sendbird-muted-avatar .sendbird-muted-avatar__icon {
    position: relative; }
    .sendbird-muted-avatar .sendbird-muted-avatar__icon .sendbird-icon {
      position: absolute;
      top: 4px;
      left: 4px; }
  .sendbird-muted-avatar .sendbird-muted-avatar__bg {
    opacity: .5;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0; }
    .sendbird-theme--light .sendbird-muted-avatar .sendbird-muted-avatar__bg {
      background-color: #6440C4; }
    .sendbird-theme--dark .sendbird-muted-avatar .sendbird-muted-avatar__bg {
      background-color: #7B53EF; }

.sendbird-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.sendbird-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.sendbird-checkbox--checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  background-color: inherit; }
  .sendbird-theme--light .sendbird-checkbox--checkmark {
    border: 2px solid #393939; }
  .sendbird-theme--dark .sendbird-checkbox--checkmark {
    border: 2px solid #A8A8A8; }

.sendbird-theme--light .sendbird-checkbox input:focus ~ .sendbird-checkbox--checkmark {
  box-shadow: 0px 0px 0px 2px #7B53EF; }

.sendbird-theme--dark .sendbird-checkbox input:focus ~ .sendbird-checkbox--checkmark {
  box-shadow: 0px 0px 0px 2px #9E8CF5; }

.sendbird-theme--light .sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark {
  background-color: #7B53EF;
  border: 2px solid #7B53EF; }

.sendbird-theme--dark .sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark {
  background-color: #9E8CF5;
  border: 2px solid #9E8CF5; }

.sendbird-checkbox--checkmark:after {
  content: "";
  position: absolute;
  display: none; }

.sendbird-checkbox input:checked ~ .sendbird-checkbox--checkmark:after {
  display: block; }

.sendbird-theme--light .sendbird-checkbox .sendbird-checkbox--checkmark:after {
  left: 5px;
  top: -0.5px;
  width: 7px;
  height: 12px;
  border: solid #FFFFFF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); }

.sendbird-theme--dark .sendbird-checkbox .sendbird-checkbox--checkmark:after {
  left: 5px;
  top: -0.5px;
  width: 7px;
  height: 12px;
  border: solid #161616;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); }

.sendbird-theme--light .sendbird__user-profile {
  background-color: #FFFFFF; }

.sendbird-theme--dark .sendbird__user-profile {
  background-color: #2C2C2C; }

.sendbird__user-profile {
  width: 320px;
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  text-align: center; }
  .sendbird-theme--light .sendbird__user-profile {
    box-shadow: 0 3px 5px -3px rgba(33, 34, 66, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird__user-profile {
    box-shadow: 0 3px 5px -3px rgba(33, 34, 66, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.12); }
  .sendbird__user-profile .sendbird__user-profile-avatar {
    margin: 8px 0px; }
  .sendbird__user-profile .sendbird__user-profile-name {
    max-width: 270px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis; }
  .sendbird__user-profile .sendbird__user-profile-message {
    margin-top: 20px; }
    .sendbird__user-profile .sendbird__user-profile-message button {
      width: 272px; }
  .sendbird__user-profile .sendbird__user-profile-seperator {
    margin: 24px 0px;
    height: 1px; }
    .sendbird-theme--light .sendbird__user-profile .sendbird__user-profile-seperator {
      background-color: rgba(0, 0, 0, 0.12); }
    .sendbird-theme--dark .sendbird__user-profile .sendbird__user-profile-seperator {
      background-color: rgba(255, 255, 255, 0.12); }
  .sendbird__user-profile .sendbird__user-profile-userId--label,
  .sendbird__user-profile .sendbird__user-profile-userId--value {
    display: block;
    text-align: left; }
  .sendbird__user-profile .sendbird__user-profile-userId--label {
    margin-bottom: 8px; }

.sendbird__offline .sendbird-dropdown__menu .sendbird-dropdown__menu-item {
  cursor: not-allowed; }

.sendbird-dropdown__menu {
  z-index: 99999;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 140px;
  margin: 0px;
  padding: 8px 0px;
  border-radius: 4px;
  list-style: none; }
  .sendbird-theme--light .sendbird-dropdown__menu {
    box-shadow: 0 3px 5px -3px rgba(33, 34, 66, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-dropdown__menu {
    box-shadow: 0 3px 5px -3px rgba(33, 34, 66, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.12);
    background-color: #2C2C2C; }
  .sendbird-dropdown__menu .sendbird-dropdown__menu-item {
    cursor: pointer;
    white-space: nowrap;
    padding: 8px 16px; }
    .sendbird-theme--light .sendbird-dropdown__menu .sendbird-dropdown__menu-item:hover {
      background-color: #F0F0F0; }
    .sendbird-theme--dark .sendbird-dropdown__menu .sendbird-dropdown__menu-item:hover {
      background-color: #393939; }

.sendbird-dropdown__reaction-bar {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 44px;
  max-width: 352px;
  max-height: 208px;
  overflow-y: scroll;
  margin: 0px;
  padding: 8px;
  border-radius: 8px;
  list-style: none; }
  .sendbird-theme--light .sendbird-dropdown__reaction-bar {
    box-shadow: 0 3px 5px -3px rgba(33, 34, 66, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-dropdown__reaction-bar {
    box-shadow: 0 3px 5px -3px rgba(33, 34, 66, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.08), 0 8px 10px 1px rgba(0, 0, 0, 0.12);
    background-color: #2C2C2C; }
  .sendbird-dropdown__reaction-bar .sendbird-dropdown__reaction-bar__row .sendbird-reaction-button, .sendbird-dropdown__reaction-bar .sendbird-dropdown__reaction-bar__row .sendbird-reaction-button--selected {
    margin: 4px; }

.sendbird-dropdown__menu-backdrop {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.sendbird-reactions--pressed {
  display: block !important; }

.sendbird-icon--pressed {
  display: block !important; }

.sendbird-sort-by-row {
  display: flex;
  justify-content: start;
  align-items: center; }

.sendbird-user-list-item {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 432px;
  height: 56px; }
  .sendbird-theme--light .sendbird-user-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-user-list-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #2C2C2C; }
  .sendbird-user-list-item__avatar {
    position: absolute;
    top: 8px;
    left: 0px;
    z-index: 1;
    cursor: pointer; }
  .sendbird-user-list-item .sendbird-muted-avatar {
    position: absolute;
    top: 8px;
    left: 0px;
    z-index: 2;
    pointer-events: none; }
  .sendbird-user-list-item__title {
    position: absolute;
    top: 17px;
    left: 56px;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .sendbird-user-list-item__subtitle {
    position: absolute;
    top: 23px;
    left: 140px;
    max-width: 237px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .sendbird-user-list-item__checkbox {
    position: absolute;
    top: 16px;
    right: 16px; }
  .sendbird-user-list-item__action {
    position: absolute;
    right: 0;
    top: 10px; }
  .sendbird-user-list-item__operator {
    position: absolute;
    right: 40px;
    top: 22px; }

.sendbird-place-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .sendbird-place-holder .sendbird-place-holder__body {
    display: flex;
    flex-direction: column;
    height: 104px;
    align-items: center; }
    .sendbird-place-holder .sendbird-place-holder__body .sendbird-place-holder__body__icon {
      margin-bottom: 10px; }
    .sendbird-place-holder .sendbird-place-holder__body .sendbird-place-holder__body__text {
      margin-top: 10px; }
  .sendbird-place-holder .sendbird-place-holder__body__reconnect {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    cursor: pointer; }
    .sendbird-place-holder .sendbird-place-holder__body__reconnect .sendbird-place-holder__body__reconnect__icon {
      margin-right: 4px; }

.sendbird-loader {
  display: inline-block;
  -webkit-animation: 1s infinite linear;
          animation: 1s infinite linear;
  -webkit-animation-name: rotate;
          animation-name: rotate; }

@-webkit-keyframes rotate {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }

@keyframes rotate {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }

.sendbird-channel-list {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .sendbird-theme--light .sendbird-channel-list {
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-channel-list {
    background-color: #161616;
    border: 1px solid rgba(255, 255, 255, 0.12); }

.sendbird-channel-list__header {
  height: 64px;
  min-height: 64px; }

.sendbird-channel-list__body {
  flex: 1 1 0;
  -ms-flex: 1;
  overflow-y: auto;
  overflow-x: hidden; }

.sendbird-conversation {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .sendbird-theme--light .sendbird-conversation {
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-conversation {
    background-color: #161616;
    border: 1px solid rgba(255, 255, 255, 0.12); }

.sendbird-conversation__messages {
  overflow-y: auto;
  flex: 1 1 0;
  order: 2; }

.sendbird-conversation__messages-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end; }

.sendbird-conversation__footer {
  order: 3;
  padding: 0px 24px 24px 24px; }

.sendbird-conversation__scroll-container {
  display: flex;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column; }
  .sendbird-conversation__scroll-container .sendbird-conversation__padding {
    flex: 1 1 0; }

.sendbird-conversation__footer {
  position: relative; }
  .sendbird-conversation__footer .sendbird-conversation__typing-indicator {
    position: absolute;
    bottom: 8px; }

.sendbird-reaction-button {
  border-radius: 8px;
  display: inline-block;
  border: solid 1px transparent;
  cursor: pointer; }
  .sendbird-theme--light .sendbird-reaction-button:hover {
    border: solid 1px #F0F0F0;
    background-color: #F0F0F0; }
  .sendbird-theme--dark .sendbird-reaction-button:hover {
    border: solid 1px #393939;
    background-color: #393939; }
  .sendbird-reaction-button__inner {
    margin: 3px; }

.sendbird-reaction-button--selected {
  cursor: pointer;
  border-radius: 8px;
  display: inline-block; }
  .sendbird-theme--light .sendbird-reaction-button--selected {
    border: solid 1px #E2DFFF;
    background-color: #E2DFFF; }
  .sendbird-theme--dark .sendbird-reaction-button--selected {
    border: solid 1px #6440C4;
    background-color: #6440C4; }
  .sendbird-reaction-button--selected:hover {
    border: solid 1px #9E8CF5; }
  .sendbird-reaction-button--selected__inner {
    margin: 3px; }
  .sendbird-reaction-button--selected.sendbird-reactions--pressed {
    display: block !important; }

.sendbird-conversation__messages .sendbird-conversation__messages-padding {
  padding-left: 24px;
  padding-right: 24px; }

.sendbird-conversation__messages .sendbird-separator,
.sendbird-conversation__messages .sendbird-admin-message {
  padding-top: 8px;
  padding-bottom: 8px; }

.sendbird-message {
  position: relative;
  line-height: 0; }
  .sendbird-message--outgoing {
    text-align: right; }
  .sendbird-message--incoming {
    text-align: left; }

.sendbird-user-message--copy {
  cursor: pointer !important; }

.sendbird-user-message--outgoing {
  position: relative;
  right: 0px;
  display: inline-block;
  max-width: 496px;
  text-align: right; }
  .sendbird-theme--light .sendbird-user-message--outgoing:hover .sendbird-user-message__text-balloon {
    background-color: #9E8CF5; }
  .sendbird-theme--dark .sendbird-user-message--outgoing:hover .sendbird-user-message__text-balloon {
    background-color: #6440C4; }
  .sendbird-theme--light .sendbird-user-message--outgoing:hover .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
    border: solid 1px #9E8CF5; }
  .sendbird-theme--dark .sendbird-user-message--outgoing:hover .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
    border: solid 1px #6440C4; }
  .sendbird-user-message--outgoing:hover .sendbird-user-message__left-padding .sendbird-user-message__more {
    display: block; }
  .sendbird-user-message--outgoing .sendbird-user-message--inner {
    display: flex;
    flex-direction: row; }
  .sendbird-user-message--outgoing .sendbird-user-message__left-padding {
    position: relative;
    width: 68px; }
    .sendbird-user-message--outgoing .sendbird-user-message__left-padding .sendbird-user-message__more {
      display: none;
      position: absolute;
      right: 4px;
      top: 2px; }
      .sendbird-user-message--outgoing .sendbird-user-message__left-padding .sendbird-user-message__more[class*='--pressed'] {
        display: block !important; }
    .sendbird-user-message--outgoing .sendbird-user-message__left-padding .sendbird-user-message__status {
      position: absolute;
      bottom: 2px;
      right: 4px; }
  .sendbird-user-message--outgoing .sendbird-user-message__text-balloon {
    max-width: 404px;
    box-sizing: border-box;
    border-radius: 16px;
    text-align: left; }
    .sendbird-theme--light .sendbird-user-message--outgoing .sendbird-user-message__text-balloon {
      background-color: #E2DFFF; }
    .sendbird-theme--dark .sendbird-user-message--outgoing .sendbird-user-message__text-balloon {
      background-color: #7B53EF; }
    .sendbird-user-message--outgoing .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner {
      display: flex;
      flex-direction: column; }
      .sendbird-user-message--outgoing .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__text-place {
        padding: 8px 12px; }
        .sendbird-user-message--outgoing .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__text-place .sendbird-user-message__text-balloon__inner__text-place__text {
          display: inline;
          white-space: pre-wrap;
          overflow-wrap: normal; }
      .sendbird-user-message--outgoing .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
        position: relative;
        bottom: 0px; }
        .sendbird-theme--light .sendbird-user-message--outgoing .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
          border: solid 1px #E2DFFF; }
        .sendbird-theme--dark .sendbird-user-message--outgoing .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
          border: solid 1px #7B53EF; }

.sendbird-user-message--incoming {
  position: relative;
  left: 0px;
  display: inline-block;
  padding-left: 40px;
  max-width: 536px;
  text-align: left; }
  .sendbird-theme--light .sendbird-user-message--incoming:hover .sendbird-user-message__text-balloon {
    background-color: #D9D9D9; }
  .sendbird-theme--dark .sendbird-user-message--incoming:hover .sendbird-user-message__text-balloon {
    background-color: #393939; }
  .sendbird-theme--light .sendbird-user-message--incoming:hover .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
    border: solid 1px #D9D9D9; }
  .sendbird-theme--dark .sendbird-user-message--incoming:hover .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
    border: solid 1px #393939; }
  .sendbird-user-message--incoming:hover .sendbird-user-message__right-padding .sendbird-user-message__more {
    display: block; }
  .sendbird-user-message--incoming:hover .sendbird-user-message__right-padding .sendbird-user-message__sent-at {
    display: none; }
  .sendbird-user-message--incoming .sendbird-user-message--inner {
    position: relative;
    display: flex;
    flex-direction: row; }
  .sendbird-user-message--incoming .sendbird-user-message--body {
    display: flex;
    flex-direction: column; }
  .sendbird-user-message--incoming .sendbird-user-message__right-padding {
    position: relative;
    width: 68px; }
    .sendbird-user-message--incoming .sendbird-user-message__right-padding .sendbird-user-message__sent-at {
      position: absolute;
      left: 4px;
      bottom: 2px; }
    .sendbird-user-message--incoming .sendbird-user-message__right-padding .sendbird-user-message__more {
      position: absolute;
      display: none;
      top: 18px;
      left: 4px; }
  .sendbird-user-message--incoming .sendbird-user-message__avatar {
    position: absolute;
    bottom: 4px;
    left: -40px;
    cursor: pointer; }
  .sendbird-user-message--incoming .sendbird-user-message__sender-name {
    margin-left: 12px;
    margin-bottom: 4px; }
  .sendbird-user-message--incoming .sendbird-user-message__text-balloon {
    max-width: 404px;
    box-sizing: border-box;
    border-radius: 16px; }
    .sendbird-theme--light .sendbird-user-message--incoming .sendbird-user-message__text-balloon {
      background-color: #F0F0F0; }
    .sendbird-theme--dark .sendbird-user-message--incoming .sendbird-user-message__text-balloon {
      background-color: #2C2C2C; }
    .sendbird-user-message--incoming .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner {
      display: flex;
      flex-direction: column; }
      .sendbird-user-message--incoming .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__text-place {
        padding: 8px 12px; }
        .sendbird-user-message--incoming .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__text-place .sendbird-user-message__text-balloon__inner__text-place__text {
          display: inline;
          white-space: pre-wrap;
          overflow-wrap: normal; }
          .sendbird-theme--light .sendbird-user-message--incoming .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__text-place .sendbird-user-message__text-balloon__inner__text-place__text {
            color: rgba(0, 0, 0, 0.88); }
          .sendbird-theme--dark .sendbird-user-message--incoming .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__text-place .sendbird-user-message__text-balloon__inner__text-place__text {
            color: rgba(255, 255, 255, 0.88); }
      .sendbird-user-message--incoming .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
        position: relative;
        bottom: 0px; }
        .sendbird-theme--light .sendbird-user-message--incoming .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
          border: solid 1px #F0F0F0; }
        .sendbird-theme--dark .sendbird-user-message--incoming .sendbird-user-message__text-balloon .sendbird-user-message__text-balloon__inner .sendbird-user-message__text-balloon__inner__emoji-reactions {
          border: solid 1px #2C2C2C; }

.sendbird-user-message__text-balloon__inner__text-place__text {
  word-break: break-word;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.sendbird-user-message__text-balloon {
  display: inline-block; }

.sendbird-user-message-word {
  display: inline;
  margin: 0px 2px; }

.sendbird-message-status {
  display: inline;
  text-align: right; }
  .sendbird-message-status .sendbird-message-status__text .sendbird-message-status__text__try-again {
    text-decoration: underline; }
    .sendbird-message-status .sendbird-message-status__text .sendbird-message-status__text__try-again:hover {
      cursor: pointer; }
  .sendbird-message-status .sendbird-message-status__text .sendbird-message-status__text__delete {
    text-decoration: underline; }
    .sendbird-message-status .sendbird-message-status__text .sendbird-message-status__text__delete:hover {
      cursor: pointer; }

.sendbird-emoji-reactions {
  display: inline-block;
  border-radius: 16px;
  max-width: 404px;
  box-sizing: border-box;
  min-height: 24px;
  padding: 7px 5px 3px 5px; }
  .sendbird-theme--light .sendbird-emoji-reactions {
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-emoji-reactions {
    background-color: #161616; }
  .sendbird-emoji-reactions .sendbird-emoji-reactions--inner {
    display: flex;
    flex-wrap: wrap; }
  .sendbird-emoji-reactions .sendbird-emoji-reactions__emoji-reaction {
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 4px; }
  .sendbird-emoji-reactions .sendbird-emoji-reactions__emoji-reaction-add {
    width: 36px;
    height: 24px; }
  .sendbird-emoji-reactions:focus {
    outline: none; }

.sendbird-reaction-badge {
  display: inline-block;
  border-radius: 15px;
  width: 50px;
  height: 24px; }
  .sendbird-theme--light .sendbird-reaction-badge {
    background-color: #F0F0F0;
    border: solid 1px #F0F0F0; }
  .sendbird-theme--dark .sendbird-reaction-badge {
    background-color: #2C2C2C;
    border: solid 1px #2C2C2C; }
  .sendbird-reaction-badge:hover {
    cursor: pointer; }
    .sendbird-theme--light .sendbird-reaction-badge:hover {
      border: solid 1px #7B53EF; }
    .sendbird-theme--dark .sendbird-reaction-badge:hover {
      border: solid 1px #9E8CF5; }

.sendbird-reaction-badge--is-add {
  display: inline-block;
  border-radius: 15px;
  width: 36px;
  height: 24px; }
  .sendbird-theme--light .sendbird-reaction-badge--is-add {
    background-color: #F0F0F0;
    border: solid 1px #F0F0F0; }
  .sendbird-theme--dark .sendbird-reaction-badge--is-add {
    background-color: #2C2C2C;
    border: solid 1px #2C2C2C; }
  .sendbird-reaction-badge--is-add:hover {
    cursor: pointer;
    border: solid 1px #9E8CF5; }
  .sendbird-reaction-badge--is-add .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
    left: 8px; }

.sendbird-reaction-badge--selected {
  display: inline-block;
  border-radius: 15px;
  width: 50px;
  height: 24px; }
  .sendbird-theme--light .sendbird-reaction-badge--selected {
    background-color: #E2DFFF;
    border: solid 1px #E2DFFF; }
  .sendbird-theme--dark .sendbird-reaction-badge--selected {
    background-color: #6440C4;
    border: solid 1px #6440C4; }
  .sendbird-reaction-badge--selected:hover {
    cursor: pointer; }
    .sendbird-theme--light .sendbird-reaction-badge--selected:hover {
      border: solid 1px #7B53EF; }
    .sendbird-theme--dark .sendbird-reaction-badge--selected:hover {
      border: solid 1px #9E8CF5; }

.sendbird-reaction-badge__inner {
  position: relative;
  display: flex;
  height: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 4px; }
  .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__icon {
    position: absolute;
    top: 2px;
    left: 4px; }
  .sendbird-reaction-badge__inner .sendbird-reaction-badge__inner__count {
    margin-left: 4px; }

.sendbird-tooltip {
  position: relative;
  display: inline-flex;
  justify-content: center;
  max-width: 200px;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: #000000; }
  .sendbird-tooltip::after {
    content: '';
    position: absolute;
    bottom: -4px;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    background-color: #000000; }
  .sendbird-tooltip__text {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.88); }

.sendbird-tooltip-wrapper {
  position: relative;
  display: inline-flex; }
  .sendbird-tooltip-wrapper__hover-tooltip {
    position: absolute;
    display: inline-flex;
    width: 200px; }
    .sendbird-tooltip-wrapper__hover-tooltip__inner__tooltip-container {
      position: relative;
      display: inline-flex; }

.sendbird-admin-message {
  display: flex;
  justify-content: center;
  align-items: center; }
  .sendbird-admin-message .sendbird-admin-message__text {
    display: flex; }

.sendbird-thumbnail {
  text-align: right;
  position: relative; }
  .sendbird-thumbnail .sendbird-thumbnail__body {
    position: relative;
    display: inline-block; }
  .sendbird-thumbnail .sendbird-thumbnail__more {
    display: none;
    position: absolute;
    top: 10px; }
  .sendbird-thumbnail:hover .sendbird-thumbnail__more {
    display: block; }
  .sendbird-thumbnail .sendbird-thumbnail__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    margin: 8px 0px; }
    .sendbird-theme--light .sendbird-thumbnail .sendbird-thumbnail__wrap {
      background-color: #F0F0F0; }
    .sendbird-theme--dark .sendbird-thumbnail .sendbird-thumbnail__wrap {
      background-color: #2C2C2C; }
    .sendbird-thumbnail .sendbird-thumbnail__wrap:focus {
      outline: none; }
    .sendbird-theme--light .sendbird-thumbnail .sendbird-thumbnail__wrap:hover {
      background-color: #D9D9D9; }
    .sendbird-theme--dark .sendbird-thumbnail .sendbird-thumbnail__wrap:hover {
      background-color: #393939; }
    .sendbird-thumbnail .sendbird-thumbnail__wrap:hover .sendbird-thumbnail__wrap__inner {
      cursor: pointer; }
      .sendbird-thumbnail .sendbird-thumbnail__wrap:hover .sendbird-thumbnail__wrap__inner .sendbird-thumbnail__wrap__overlay {
        opacity: 1; }
    .sendbird-theme--light .sendbird-thumbnail .sendbird-thumbnail__wrap:hover .sendbird-thumbnail__wrap__emoji-reactions {
      border: solid 1px #D9D9D9; }
    .sendbird-theme--dark .sendbird-thumbnail .sendbird-thumbnail__wrap:hover .sendbird-thumbnail__wrap__emoji-reactions {
      border: solid 1px #393939; }
    .sendbird-thumbnail .sendbird-thumbnail__wrap .sendbird-thumbnail__wrap__inner {
      width: 100%;
      height: 100%;
      position: relative; }
      .sendbird-thumbnail .sendbird-thumbnail__wrap .sendbird-thumbnail__wrap__inner .sendbird-thumbnail__wrap__overlay {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 16px; }
        .sendbird-theme--light .sendbird-thumbnail .sendbird-thumbnail__wrap .sendbird-thumbnail__wrap__inner .sendbird-thumbnail__wrap__overlay {
          background-color: rgba(0, 0, 0, 0.32); }
        .sendbird-theme--dark .sendbird-thumbnail .sendbird-thumbnail__wrap .sendbird-thumbnail__wrap__inner .sendbird-thumbnail__wrap__overlay {
          background-color: rgba(0, 0, 0, 0.32); }
    .sendbird-theme--light .sendbird-thumbnail .sendbird-thumbnail__wrap .sendbird-thumbnail__wrap__emoji-reactions {
      border: solid 1px #F0F0F0; }
    .sendbird-theme--dark .sendbird-thumbnail .sendbird-thumbnail__wrap .sendbird-thumbnail__wrap__emoji-reactions {
      border: solid 1px #2C2C2C; }
  .sendbird-thumbnail .sendbird-thumbnail__avatar {
    position: absolute;
    bottom: 30px;
    left: 0px; }
  .sendbird-thumbnail .sendbird-thumbnail__sender-name {
    margin-left: 12px; }
  .sendbird-thumbnail.sendbird-thumbnail--incoming {
    text-align: left;
    padding-left: 40px;
    box-sizing: border-box; }
  .sendbird-thumbnail .sendbird-thumbnail__video-icon {
    position: absolute;
    top: calc(50% - 28px);
    left: calc(50% - 28px); }
  .sendbird-thumbnail .sendbird-thumbnail__video,
  .sendbird-thumbnail .sendbird-thumbnail__img {
    display: block;
    max-width: 404px;
    max-height: 360px;
    border-radius: 16px; }

.sendbird-outgoing-thumbnail-message {
  position: relative;
  text-align: right;
  line-height: 0px; }
  .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message--inner {
    display: inline-flex;
    flex-direction: row; }
  .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__left-padding {
    position: relative;
    width: 68px; }
    .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__left-padding .sendbird-outgoing-thumbnail-message-left-padding__more {
      display: none;
      position: absolute;
      top: 4px;
      right: 4px; }
    .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__left-padding .sendbird-outgoing-thumbnail-message-left-padding__status {
      position: absolute;
      right: 4px;
      bottom: 4px; }
  .sendbird-outgoing-thumbnail-message:hover .sendbird-outgoing-thumbnail-message__left-padding .sendbird-outgoing-thumbnail-message-left-padding__more {
    display: block; }
  .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body {
    position: relative; }
    .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 16px; }
      .sendbird-theme--light .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap {
        background-color: #F0F0F0; }
      .sendbird-theme--dark .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap {
        background-color: #2C2C2C; }
      .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap:focus {
        outline: none; }
      .sendbird-theme--light .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap:hover {
        background-color: #D9D9D9; }
      .sendbird-theme--dark .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap:hover {
        background-color: #393939; }
      .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap:hover .sendbird-outgoing-thumbnail-message-body__wrap--inner {
        cursor: pointer; }
        .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap:hover .sendbird-outgoing-thumbnail-message-body__wrap--inner .sendbird-outgoing-thumbnail-message-body__wrap__overlay {
          opacity: 1; }
      .sendbird-theme--light .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap:hover .sendbird-outgoing-thumbnail-message-body__wrap__emoji-reactions {
        border: solid 1px #D9D9D9; }
      .sendbird-theme--dark .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap:hover .sendbird-outgoing-thumbnail-message-body__wrap__emoji-reactions {
        border: solid 1px #393939; }
      .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap .sendbird-outgoing-thumbnail-message-body__wrap--inner {
        width: 100%;
        height: 100%;
        position: relative; }
        .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap .sendbird-outgoing-thumbnail-message-body__wrap--inner .sendbird-outgoing-thumbnail-message-body__wrap__overlay {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 16px; }
          .sendbird-theme--light .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap .sendbird-outgoing-thumbnail-message-body__wrap--inner .sendbird-outgoing-thumbnail-message-body__wrap__overlay {
            background-color: rgba(0, 0, 0, 0.32); }
          .sendbird-theme--dark .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap .sendbird-outgoing-thumbnail-message-body__wrap--inner .sendbird-outgoing-thumbnail-message-body__wrap__overlay {
            background-color: rgba(0, 0, 0, 0.32); }
      .sendbird-theme--light .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap .sendbird-outgoing-thumbnail-message-body__wrap__emoji-reactions {
        border: solid 1px #F0F0F0; }
      .sendbird-theme--dark .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message__body .sendbird-outgoing-thumbnail-message-body__wrap .sendbird-outgoing-thumbnail-message-body__wrap__emoji-reactions {
        border: solid 1px #2C2C2C; }
  .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message-body__video,
  .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message-body__img {
    display: block;
    max-width: 404px;
    max-height: 360px;
    border-radius: 16px; }
  .sendbird-outgoing-thumbnail-message .sendbird-outgoing-thumbnail-message-body__video-icon {
    position: absolute;
    top: calc(50% - 28px);
    left: calc(50% - 28px); }

.sendbird-incoming-thumbnail-message {
  position: relative;
  text-align: left;
  padding-left: 40px; }
  .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__sender-name {
    position: relative;
    box-sizing: border-box;
    left: 12px;
    height: 12px;
    margin-bottom: 4px; }
  .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__avatar {
    position: absolute;
    left: -40px;
    bottom: 4px;
    cursor: pointer; }
  .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message--inner {
    display: flex;
    flex-direction: row; }
  .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body {
    position: relative; }
    .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 16px; }
      .sendbird-theme--light .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap {
        background-color: #F0F0F0; }
      .sendbird-theme--dark .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap {
        background-color: #2C2C2C; }
      .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap:focus {
        outline: none; }
      .sendbird-theme--light .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap:hover {
        background-color: #D9D9D9; }
      .sendbird-theme--dark .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap:hover {
        background-color: #393939; }
      .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap:hover .sendbird-incoming-thumbnail-message-body__wrap--inner {
        cursor: pointer; }
        .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap:hover .sendbird-incoming-thumbnail-message-body__wrap--inner .sendbird-incoming-thumbnail-message-body__wrap-overlay {
          opacity: 1; }
      .sendbird-theme--light .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap:hover .sendbird-incoming-thumbnail-message-body__wrap__emoji-reactions {
        border: solid 1px #D9D9D9; }
      .sendbird-theme--dark .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap:hover .sendbird-incoming-thumbnail-message-body__wrap__emoji-reactions {
        border: solid 1px #393939; }
      .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap .sendbird-incoming-thumbnail-message-body__wrap--inner {
        width: 100%;
        height: 100%;
        position: relative; }
        .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap .sendbird-incoming-thumbnail-message-body__wrap--inner .sendbird-incoming-thumbnail-message-body__wrap-overlay {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          border-radius: 16px; }
          .sendbird-theme--light .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap .sendbird-incoming-thumbnail-message-body__wrap--inner .sendbird-incoming-thumbnail-message-body__wrap-overlay {
            background-color: rgba(0, 0, 0, 0.32); }
          .sendbird-theme--dark .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap .sendbird-incoming-thumbnail-message-body__wrap--inner .sendbird-incoming-thumbnail-message-body__wrap-overlay {
            background-color: rgba(0, 0, 0, 0.32); }
      .sendbird-theme--light .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap .sendbird-incoming-thumbnail-message__wrap__emoji-reactions {
        border: solid 1px #F0F0F0; }
      .sendbird-theme--dark .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__body .sendbird-incoming-thumbnail-message-body__wrap .sendbird-incoming-thumbnail-message__wrap__emoji-reactions {
        border: solid 1px #2C2C2C; }
  .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__video,
  .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__img {
    display: block;
    max-width: 404px;
    max-height: 360px;
    border-radius: 16px; }
  .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__video-icon {
    position: absolute;
    top: calc(50% - 28px);
    left: calc(50% - 28px); }
  .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__right-padding {
    position: relative;
    width: 68px; }
    .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__right-padding .sendbird-incoming-thumbnail-message__sent-at {
      position: absolute;
      left: 4px;
      bottom: 4px; }
    .sendbird-incoming-thumbnail-message .sendbird-incoming-thumbnail-message__right-padding .sendbird-incoming-thumbnail-message__more {
      position: absolute;
      left: 4px;
      top: 4px;
      display: none; }
  .sendbird-incoming-thumbnail-message:hover .sendbird-incoming-thumbnail-message__right-padding .sendbird-incoming-thumbnail-message__more {
    display: block; }

.sendbird-file-message--outgoing {
  text-align: right; }

.sendbird-file-message--incoming {
  text-align: left; }

.sendbird-file-message__outgoing {
  position: relative;
  right: 0px;
  display: inline-block;
  max-width: 496px;
  text-align: right;
  line-height: 0px; }
  .sendbird-file-message__outgoing .sendbird-file-message--inner {
    position: relative;
    display: flex;
    flex-direction: row; }
  .sendbird-theme--light .sendbird-file-message__outgoing:hover .sendbird-file-message__tooltip {
    background-color: #9E8CF5; }
  .sendbird-theme--dark .sendbird-file-message__outgoing:hover .sendbird-file-message__tooltip {
    background-color: #6440C4; }
  .sendbird-theme--light .sendbird-file-message__outgoing:hover .sendbird-file-message__tooltip .sendbird-file-message__tooltip__emoji-reactions {
    border: solid 1px #9E8CF5; }
  .sendbird-theme--dark .sendbird-file-message__outgoing:hover .sendbird-file-message__tooltip .sendbird-file-message__tooltip__emoji-reactions {
    border: solid 1px #6440C4; }
  .sendbird-file-message__outgoing:hover .sendbird-file-message__left-padding .sendbird-file-message__more {
    display: block; }
  .sendbird-file-message__outgoing:hover .sendbird-file-message__left-padding .sendbird-file-message__status {
    display: none; }
  .sendbird-file-message__outgoing .sendbird-file-message__left-padding {
    position: relative;
    width: 68px; }
    .sendbird-file-message__outgoing .sendbird-file-message__left-padding .sendbird-file-message__more {
      display: none;
      position: absolute;
      right: 4px;
      top: 2px; }
    .sendbird-file-message__outgoing .sendbird-file-message__left-padding .sendbird-file-message__status {
      position: absolute;
      text-align: right;
      right: 4px;
      bottom: 4px; }
  .sendbird-file-message__outgoing .sendbird-file-message__tooltip {
    display: flex;
    flex-direction: column;
    max-width: 404px;
    box-sizing: border-box;
    border-radius: 16px; }
    .sendbird-theme--light .sendbird-file-message__outgoing .sendbird-file-message__tooltip {
      background-color: #E2DFFF; }
    .sendbird-theme--dark .sendbird-file-message__outgoing .sendbird-file-message__tooltip {
      background-color: #7B53EF; }
    .sendbird-file-message__outgoing .sendbird-file-message__tooltip .sendbird-file-message__tooltip__inner {
      display: flex;
      flex: row;
      padding: 8px 12px;
      text-align: left; }
      .sendbird-file-message__outgoing .sendbird-file-message__tooltip .sendbird-file-message__tooltip__inner .sendbird-file-message__tooltip__icon {
        border-radius: 10px;
        margin-right: 8px;
        background-color: #FFFFFF; }
      .sendbird-file-message__outgoing .sendbird-file-message__tooltip .sendbird-file-message__tooltip__inner .sendbird-file-message__tooltip__text {
        white-space: nowrap;
        overflow-wrap: break-word;
        margin: 4px 0px;
        cursor: pointer; }
    .sendbird-theme--light .sendbird-file-message__outgoing .sendbird-file-message__tooltip .sendbird-file-message__tooltip__emoji-reactions {
      border: solid 1px #E2DFFF; }
    .sendbird-theme--dark .sendbird-file-message__outgoing .sendbird-file-message__tooltip .sendbird-file-message__tooltip__emoji-reactions {
      border: solid 1px #7B53EF; }

.sendbird-file-message__incoming {
  position: relative;
  left: 0px;
  display: inline-block;
  padding-left: 40px;
  max-width: 536px;
  text-align: left;
  line-height: 0px; }
  .sendbird-file-message__incoming .sendbird-file-message--inner {
    position: relative;
    display: flex;
    flex-direction: row; }
  .sendbird-file-message__incoming .sendbird-file-message__right-padding {
    position: relative;
    width: 68px; }
    .sendbird-file-message__incoming .sendbird-file-message__right-padding .sendbird-file-message__more {
      position: absolute;
      display: none;
      left: 4px;
      top: 18px; }
    .sendbird-file-message__incoming .sendbird-file-message__right-padding .sendbird-file-message__sent-at {
      position: absolute;
      left: 4px;
      bottom: 4px; }
  .sendbird-file-message__incoming:hover .sendbird-file-message__right-padding .sendbird-file-message__more {
    display: block; }
  .sendbird-theme--light .sendbird-file-message__incoming:hover .sendbird-file-message__tooltip {
    background-color: #D9D9D9; }
  .sendbird-theme--dark .sendbird-file-message__incoming:hover .sendbird-file-message__tooltip {
    background-color: #393939; }
  .sendbird-theme--light .sendbird-file-message__incoming:hover .sendbird-file-message__tooltip .sendbird-file-message__tooltip__emoji-reactions {
    border: solid 1px #D9D9D9; }
  .sendbird-theme--dark .sendbird-file-message__incoming:hover .sendbird-file-message__tooltip .sendbird-file-message__tooltip__emoji-reactions {
    border: solid 1px #393939; }
  .sendbird-file-message__incoming .sendbird-file-message__avatar {
    position: absolute;
    bottom: 2px;
    left: -40px;
    cursor: pointer; }
  .sendbird-file-message__incoming .sendbird-file-message__sender-name {
    margin-left: 12px;
    margin-bottom: 4px; }
  .sendbird-file-message__incoming .sendbird-file-message__tooltip {
    display: flex;
    flex-direction: column;
    max-width: 404px;
    box-sizing: border-box;
    border-radius: 16px; }
    .sendbird-theme--light .sendbird-file-message__incoming .sendbird-file-message__tooltip {
      background-color: #F0F0F0; }
    .sendbird-theme--dark .sendbird-file-message__incoming .sendbird-file-message__tooltip {
      background-color: #2C2C2C; }
    .sendbird-file-message__incoming .sendbird-file-message__tooltip .sendbird-file-message__tooltip__inner {
      display: flex;
      flex-direction: row;
      padding: 8px 12px; }
      .sendbird-file-message__incoming .sendbird-file-message__tooltip .sendbird-file-message__tooltip__inner .sendbird-file-message__tooltip__icon {
        border-radius: 10px;
        margin-right: 8px; }
        .sendbird-theme--light .sendbird-file-message__incoming .sendbird-file-message__tooltip .sendbird-file-message__tooltip__inner .sendbird-file-message__tooltip__icon {
          background-color: #FFFFFF; }
        .sendbird-theme--dark .sendbird-file-message__incoming .sendbird-file-message__tooltip .sendbird-file-message__tooltip__inner .sendbird-file-message__tooltip__icon {
          background-color: #161616; }
      .sendbird-file-message__incoming .sendbird-file-message__tooltip .sendbird-file-message__tooltip__inner .sendbird-file-message__tooltip__text {
        white-space: nowrap;
        overflow-wrap: normal;
        margin: 4px 0px;
        cursor: pointer; }
    .sendbird-theme--light .sendbird-file-message__incoming .sendbird-file-message__tooltip .sendbird-file-message__tooltip__emoji-reactions {
      border: solid 1px #F0F0F0; }
    .sendbird-theme--dark .sendbird-file-message__incoming .sendbird-file-message__tooltip .sendbird-file-message__tooltip__emoji-reactions {
      border: solid 1px #2C2C2C; }

.sendbird-separator {
  width: 100%;
  display: flex;
  align-items: center; }
  .sendbird-separator .sendbird-separator__left {
    border: none;
    height: 1px;
    display: inline-block;
    width: 100%; }
  .sendbird-separator .sendbird-separator__right {
    border: none;
    height: 1px;
    display: inline-block;
    width: 100%; }
  .sendbird-separator .sendbird-separator__text {
    margin: 0px 16px;
    display: flex;
    white-space: nowrap; }

.sendbird-message-input {
  display: inline-block;
  width: 100%;
  position: relative; }
  .sendbird-message-input .sendbird-message-input--textarea {
    width: 100%;
    border-radius: 4px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    height: 56px;
    overflow-y: hidden;
    letter-spacing: normal;
    padding: 18px 64px 18px 16px;
    box-sizing: border-box;
    resize: none;
    font-family: "Open Sans", sans-serif;
    background-color: inherit; }
    .sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea {
      color: rgba(0, 0, 0, 0.88);
      caret-color: #7B53EF;
      border: solid 1px rgba(0, 0, 0, 0.38);
      background-color: #FFFFFF; }
    .sendbird-theme--dark .sendbird-message-input .sendbird-message-input--textarea {
      color: rgba(255, 255, 255, 0.88);
      caret-color: #9E8CF5;
      border: solid 1px rgba(255, 255, 255, 0.38);
      background-color: #2C2C2C; }
    .sendbird-message-input .sendbird-message-input--textarea:focus {
      outline: none; }
      .sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea:focus {
        color: rgba(0, 0, 0, 0.88);
        border: solid 1px #7B53EF;
        box-shadow: 0 1px 5px 0 rgba(33, 34, 66, 0.12), 0 0 1px 0 rgba(33, 34, 66, 0.16), 0 2px 1px 0 rgba(33, 34, 66, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
      .sendbird-theme--dark .sendbird-message-input .sendbird-message-input--textarea:focus {
        color: rgba(255, 255, 255, 0.88);
        border: solid 1px #9E8CF5;
        box-shadow: 0 1px 5px 0 rgba(33, 34, 66, 0.12), 0 0 1px 0 rgba(33, 34, 66, 0.16), 0 2px 1px 0 rgba(33, 34, 66, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
    .sendbird-theme--light .sendbird-message-input .sendbird-message-input--textarea:active {
      border: solid 1px #7B53EF;
      color: rgba(0, 0, 0, 0.88); }
    .sendbird-theme--dark .sendbird-message-input .sendbird-message-input--textarea:active {
      border: solid 1px #7B53EF;
      color: rgba(255, 255, 255, 0.88); }
  .sendbird-message-input .sendbird-message-input--placeholder {
    position: absolute;
    pointer-events: none;
    top: 18px;
    left: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 1.43; }
    .sendbird-theme--light .sendbird-message-input .sendbird-message-input--placeholder {
      color: rgba(0, 0, 0, 0.38); }
    .sendbird-theme--dark .sendbird-message-input .sendbird-message-input--placeholder {
      color: rgba(255, 255, 255, 0.38); }
  .sendbird-message-input .sendbird-message-input--attach {
    position: absolute;
    right: 12px;
    bottom: 18px;
    padding: 6px; }
  .sendbird-message-input .sendbird-message-input--send {
    position: absolute;
    padding: 6px;
    right: 16px;
    bottom: 16px; }
  .sendbird-message-input .sendbird-message-input--attach-input {
    display: none; }

.sendbird-message-input__edit {
  padding: 16px;
  box-sizing: border-box; }
  .sendbird-theme--light .sendbird-message-input__edit {
    background-color: #E2DFFF; }
  .sendbird-theme--dark .sendbird-message-input__edit {
    background-color: #000000; }

.sendbird-message-input--edit-action {
  text-align: right;
  margin-top: 4px; }
  .sendbird-message-input--edit-action button:last-child {
    margin-left: 8px; }
  .sendbird-message-input--edit-action .sendbird-message-input--edit-action__cancel {
    width: 80px; }
    .sendbird-theme--light .sendbird-message-input--edit-action .sendbird-message-input--edit-action__cancel {
      border: solid 1px rgba(0, 0, 0, 0.38); }
    .sendbird-theme--dark .sendbird-message-input--edit-action .sendbird-message-input--edit-action__cancel {
      border: solid 1px rgba(255, 255, 255, 0.38); }
  .sendbird-message-input--edit-action .sendbird-message-input--edit-action__save {
    width: 80px; }

.sendbird-message-input-form__disabled {
  cursor: not-allowed; }

.sendbird-message-input__disabled {
  pointer-events: none;
  cursor: disabled; }
  .sendbird-theme--light .sendbird-message-input__disabled .sendbird-message-input--textarea {
    background-color: #F0F0F0; }
  .sendbird-theme--dark .sendbird-message-input__disabled .sendbird-message-input--textarea {
    background-color: #2C2C2C; }
  .sendbird-theme--light .sendbird-message-input__disabled svg {
    fill: rgba(0, 0, 0, 0.38); }
  .sendbird-theme--dark .sendbird-message-input__disabled svg {
    fill: rgba(255, 255, 255, 0.38); }

.sendbird-fileviewer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000; }
  .sendbird-theme--light .sendbird-fileviewer {
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-fileviewer {
    background-color: #161616; }

.sendbird-fileviewer__header {
  height: 64px;
  min-height: 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between; }
  .sendbird-theme--light .sendbird-fileviewer__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-fileviewer__header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12); }
  .sendbird-fileviewer__header .sendbird-fileviewer__header-left {
    padding: 16px 0px 16px 20px;
    display: flex;
    flex: 1; }
  .sendbird-fileviewer__header .sendbird-fileviewer__header-right {
    display: flex;
    width: 160px;
    justify-content: flex-end; }
  .sendbird-fileviewer__header .sendbird-fileviewer__header-download,
  .sendbird-fileviewer__header .sendbird-fileviewer__header-delete,
  .sendbird-fileviewer__header .sendbird-fileviewer__header-close {
    width: 64px;
    padding: 21px;
    box-sizing: border-box;
    cursor: pointer; }
    .sendbird-theme--light .sendbird-fileviewer__header .sendbird-fileviewer__header-download path, .sendbird-theme--light
    .sendbird-fileviewer__header .sendbird-fileviewer__header-delete path, .sendbird-theme--light
    .sendbird-fileviewer__header .sendbird-fileviewer__header-close path {
      fill: rgba(0, 0, 0, 0.88); }
    .sendbird-theme--dark .sendbird-fileviewer__header .sendbird-fileviewer__header-download path, .sendbird-theme--dark
    .sendbird-fileviewer__header .sendbird-fileviewer__header-delete path, .sendbird-theme--dark
    .sendbird-fileviewer__header .sendbird-fileviewer__header-close path {
      fill: rgba(255, 255, 255, 0.88); }
  .sendbird-theme--light .sendbird-fileviewer__header .sendbird-fileviewer__header-close {
    border-left: 1px solid rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-fileviewer__header .sendbird-fileviewer__header-close {
    border-left: 1px solid rgba(255, 255, 255, 0.12); }

.sendbird-fileviewer__header-avatar {
  margin-right: 8px; }

.sendbird-fileviewer__header-actions {
  right: 0;
  display: flex;
  flex-direction: row; }

.sendbird-fileviewer__header-filename {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding-top: 2px; }

.sendbird-fileviewer__header-sendername {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-left: 8px;
  padding-top: 5px; }

.sendbird-fileviewer__content {
  height: calc(100% - 72px);
  margin-top: 8px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center; }
  .sendbird-fileviewer__content .sendbird-fileviewer__video {
    max-width: 100%;
    max-height: 100%; }
  .sendbird-fileviewer__content .sendbird-fileviewer__img {
    max-width: 90%;
    max-height: 90%; }
  .sendbird-fileviewer__content .sendbird-fileviewer__unsupported {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.sendbird-unknown-message {
  position: relative;
  line-height: 0px; }
  .sendbird-unknown-message--outgoing {
    text-align: right; }
  .sendbird-unknown-message--incoming {
    text-align: left; }

.sendbird-outgoing-unknown-message {
  position: relative;
  right: 0px;
  display: inline-block;
  text-align: right;
  padding-left: 36px;
  box-sizing: border-box; }
  .sendbird-outgoing-unknown-message--inner {
    position: relative;
    display: flex;
    flex-direction: row; }
  .sendbird-theme--light .sendbird-outgoing-unknown-message:hover .sendbird-outgoing-unknown-message__body__text-balloon {
    background-color: #9E8CF5; }
  .sendbird-theme--dark .sendbird-outgoing-unknown-message:hover .sendbird-outgoing-unknown-message__body__text-balloon {
    background-color: #6440C4; }
  .sendbird-outgoing-unknown-message:hover .sendbird-outgoing-unknown-message__more {
    display: block; }
  .sendbird-outgoing-unknown-message--left-padding {
    position: relative;
    width: 68px; }
    .sendbird-outgoing-unknown-message--left-padding .sendbird-outgoing-unknown-message__more {
      display: none;
      position: absolute;
      top: 4px;
      right: 4px; }
    .sendbird-outgoing-unknown-message--left-padding .sendbird-outgoing-unknown-message__message-status {
      position: absolute;
      text-align: right;
      right: 4px;
      bottom: 4px; }
  .sendbird-outgoing-unknown-message__body {
    display: flex;
    flex-direction: column; }
    .sendbird-outgoing-unknown-message__body__text-balloon {
      box-sizing: border-box;
      text-align: left;
      border-radius: 16px;
      padding: 8px 12px;
      width: 198px;
      height: 56px; }
      .sendbird-theme--light .sendbird-outgoing-unknown-message__body__text-balloon {
        background-color: #E2DFFF; }
      .sendbird-theme--dark .sendbird-outgoing-unknown-message__body__text-balloon {
        background-color: #7B53EF; }

.sendbird-incoming-unknown-message {
  position: relative;
  left: 0px;
  display: flex;
  flex-direction: row;
  text-align: left; }
  .sendbird-incoming-unknown-message--inner {
    position: relative;
    display: flex;
    flex-direction: row; }
  .sendbird-theme--light .sendbird-incoming-unknown-message:hover .sendbird-incoming-unknown-message__body__text-balloon {
    background-color: #D9D9D9; }
  .sendbird-theme--dark .sendbird-incoming-unknown-message:hover .sendbird-incoming-unknown-message__body__text-balloon {
    background-color: #393939; }
  .sendbird-incoming-unknown-message__left {
    width: 38px; }
    .sendbird-incoming-unknown-message__left__sender-profile-image {
      position: absolute;
      bottom: 4px; }
  .sendbird-incoming-unknown-message__body {
    width: 198px;
    display: flex;
    flex-direction: column; }
    .sendbird-incoming-unknown-message__body__sender-name {
      margin-left: 10px;
      margin-bottom: 4px; }
    .sendbird-incoming-unknown-message__body__text-balloon {
      width: 198px;
      height: 56px;
      border-radius: 16px;
      text-align: left;
      padding: 8px 12px;
      box-sizing: border-box; }
      .sendbird-theme--light .sendbird-incoming-unknown-message__body__text-balloon {
        background-color: #F0F0F0; }
      .sendbird-theme--dark .sendbird-incoming-unknown-message__body__text-balloon {
        background-color: #2C2C2C; }
  .sendbird-incoming-unknown-message--right-padding {
    position: relative;
    width: 68px; }
    .sendbird-incoming-unknown-message--right-padding .sendbird-incoming-unknown-message__sent-at {
      position: absolute;
      left: 4px;
      bottom: 4px; }

.sendbird-theme--light .sendbird-link-label {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-link-label {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--light .sendbird-link-label:active, .sendbird-theme--light .sendbird-link-label:visited, .sendbird-theme--light .sendbird-link-label:focus {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-link-label:active, .sendbird-theme--dark .sendbird-link-label:visited, .sendbird-theme--dark .sendbird-link-label:focus {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-link-label:focus {
  outline: none; }

.sendbird-link-label__label {
  display: inline; }

.sendbird-og-message {
  position: relative; }
  .sendbird-og-message--outgoing {
    text-align: right;
    line-height: 0px; }
  .sendbird-og-message--incoming {
    text-align: left; }

.sendbird-outgoing-og-message {
  position: relative;
  right: 0px;
  display: inline-block;
  box-sizing: border-box;
  text-align: right; }
  .sendbird-outgoing-og-message .sendbird-outgoing-og-message--inner {
    position: relative;
    display: flex;
    flex-direction: row; }
  .sendbird-theme--light .sendbird-outgoing-og-message:hover .sendbird-outgoing-og-message__text-balloon {
    background-color: #9E8CF5; }
  .sendbird-theme--dark .sendbird-outgoing-og-message:hover .sendbird-outgoing-og-message__text-balloon {
    background-color: #6440C4; }
  .sendbird-theme--light .sendbird-outgoing-og-message:hover .sendbird-outgoing-og-message__og-tag {
    background-color: #D9D9D9; }
  .sendbird-theme--dark .sendbird-outgoing-og-message:hover .sendbird-outgoing-og-message__og-tag {
    background-color: #393939; }
  .sendbird-theme--light .sendbird-outgoing-og-message:hover .sendbird-outgoing-og-message__og-tag__emoji-reactions {
    border: 1px solid #D9D9D9; }
  .sendbird-theme--dark .sendbird-outgoing-og-message:hover .sendbird-outgoing-og-message__og-tag__emoji-reactions {
    border: 1px solid #393939; }
  .sendbird-outgoing-og-message:hover .sendbird-outgoing-og-message--left-padding .sendbird-outgoing-og-message__more {
    display: block; }
  .sendbird-outgoing-og-message .sendbird-outgoing-og-message--left-padding {
    position: relative;
    width: 68px; }
    .sendbird-outgoing-og-message .sendbird-outgoing-og-message--left-padding .sendbird-outgoing-og-message__more {
      display: none;
      position: absolute;
      top: 2px;
      right: 4px; }
      .sendbird-outgoing-og-message .sendbird-outgoing-og-message--left-padding .sendbird-outgoing-og-message__more[class*='--pressed'] {
        display: block !important; }
    .sendbird-outgoing-og-message .sendbird-outgoing-og-message--left-padding .sendbird-outgoing-og-message__message-status {
      position: absolute;
      right: 4px;
      bottom: 4px;
      text-align: right; }
  .sendbird-outgoing-og-message__text-balloon {
    box-sizing: border-box;
    width: 320px;
    padding: 8px 12px;
    border-radius: 16px 16px 0px 0px;
    word-break: break-all;
    text-align: left; }
    .sendbird-theme--light .sendbird-outgoing-og-message__text-balloon {
      background-color: #E2DFFF; }
    .sendbird-theme--dark .sendbird-outgoing-og-message__text-balloon {
      background-color: #7B53EF; }
  .sendbird-outgoing-og-message__thumbnail--disabled:hover {
    cursor: not-allowed !important; }
  .sendbird-outgoing-og-message__thumbnail {
    width: 320px;
    box-sizing: border-box; }
    .sendbird-theme--light .sendbird-outgoing-og-message__thumbnail {
      background-color: #F0F0F0; }
    .sendbird-theme--dark .sendbird-outgoing-og-message__thumbnail {
      background-color: #2C2C2C; }
    .sendbird-outgoing-og-message__thumbnail__image__placeholder {
      width: 320px;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .sendbird-outgoing-og-message__thumbnail:hover {
      cursor: pointer; }
    .sendbird-outgoing-og-message__thumbnail:focus {
      outline: none; }
  .sendbird-outgoing-og-message__og-tag--disabled:hover {
    cursor: not-allowed !important; }
  .sendbird-outgoing-og-message__og-tag {
    padding: 8px 12px 12px 12px;
    border-radius: 0px 0px 16px 16px;
    text-align: left;
    box-sizing: border-box;
    width: 320px; }
    .sendbird-theme--light .sendbird-outgoing-og-message__og-tag {
      background-color: #F0F0F0; }
    .sendbird-theme--dark .sendbird-outgoing-og-message__og-tag {
      background-color: #2C2C2C; }
    .sendbird-outgoing-og-message__og-tag__title {
      max-height: 150px;
      overflow-y: hidden;
      margin-bottom: 4px; }
    .sendbird-outgoing-og-message__og-tag__description {
      margin-bottom: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .sendbird-outgoing-og-message__og-tag__url__label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .sendbird-outgoing-og-message__og-tag__emoji-reactions--wrapper:focus {
      outline: none; }
    .sendbird-outgoing-og-message__og-tag__emoji-reactions {
      margin: 8px -12px -12px -12px;
      width: 320px; }
      .sendbird-theme--light .sendbird-outgoing-og-message__og-tag__emoji-reactions {
        border: 1px solid #F0F0F0; }
      .sendbird-theme--dark .sendbird-outgoing-og-message__og-tag__emoji-reactions {
        border: 1px solid #2C2C2C; }
      .sendbird-outgoing-og-message__og-tag__emoji-reactions:hover {
        cursor: default; }
      .sendbird-outgoing-og-message__og-tag__emoji-reactions:focus {
        outline: none; }
    .sendbird-outgoing-og-message__og-tag:hover {
      cursor: pointer; }
    .sendbird-outgoing-og-message__og-tag:focus {
      outline: none; }

.sendbird-og-message-word {
  display: inline;
  margin: 0px 2px; }

.sendbird-incoming-og-message {
  position: relative;
  left: 0px;
  display: inline-block;
  box-sizing: border-box;
  text-align: left;
  padding-left: 40px; }
  .sendbird-theme--light .sendbird-incoming-og-message:hover .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__text-balloon {
    background-color: #D9D9D9; }
  .sendbird-theme--dark .sendbird-incoming-og-message:hover .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__text-balloon {
    background-color: #393939; }
  .sendbird-theme--light .sendbird-incoming-og-message:hover .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag {
    background-color: #D9D9D9; }
  .sendbird-theme--dark .sendbird-incoming-og-message:hover .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag {
    background-color: #393939; }
  .sendbird-theme--light .sendbird-incoming-og-message:hover .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--right-padding .sendbird-incoming-og-message__og-tag__emoji-reactions--wrapper .sendbird-incoming-og-message__og-tag__emoji-reactions {
    border: 1px solid #D9D9D9; }
  .sendbird-theme--dark .sendbird-incoming-og-message:hover .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--right-padding .sendbird-incoming-og-message__og-tag__emoji-reactions--wrapper .sendbird-incoming-og-message__og-tag__emoji-reactions {
    border: 1px solid #393939; }
  .sendbird-incoming-og-message:hover .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--right-padding .sendbird-incoming-og-message__more {
    display: block; }
  .sendbird-incoming-og-message .sendbird-incoming-og-message--inner {
    position: relative;
    display: flex;
    flex-direction: row; }
    .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body {
      position: relative; }
      .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__avatar {
        position: absolute;
        bottom: 4px;
        left: -40px;
        cursor: pointer; }
      .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__sender-name {
        margin-left: 12px; }
      .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__text-balloon {
        box-sizing: border-box;
        max-width: 320px;
        padding: 8px 12px;
        border-radius: 16px 16px 0px 0px;
        word-break: break-all;
        text-align: left; }
        .sendbird-theme--light .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__text-balloon {
          background-color: #F0F0F0; }
        .sendbird-theme--dark .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__text-balloon {
          background-color: #2C2C2C; }
      .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__thumbnail--disabled:hover {
        cursor: not-allowed !important; }
      .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__thumbnail {
        box-sizing: border-box;
        width: 320px; }
        .sendbird-theme--light .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__thumbnail {
          background-color: #F0F0F0; }
        .sendbird-theme--dark .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__thumbnail {
          background-color: #2C2C2C; }
        .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__thumbnail .sendbird-incoming-og-message__thumbnail__image__placeholder {
          width: 320px;
          height: 160px;
          display: flex;
          justify-content: center;
          align-items: center; }
        .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__thumbnail:hover {
          cursor: pointer; }
        .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__thumbnail:focus {
          outline: none; }
      .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag--disabled:hover {
        cursor: not-allowed !important; }
      .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag {
        padding: 8px 12px 12px 12px;
        border-radius: 0px 0px 16px 16px;
        text-align: left;
        box-sizing: border-box;
        width: 320px; }
        .sendbird-theme--light .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag {
          background-color: #F0F0F0; }
        .sendbird-theme--dark .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag {
          background-color: #2C2C2C; }
        .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag .sendbird-incoming-og-message__og-tag__title {
          max-height: 150px;
          overflow-y: hidden;
          margin-bottom: 4px; }
        .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag .sendbird-incoming-og-message__og-tag__description {
          margin-bottom: 8px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
        .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag .sendbird-incoming-og-message__og-tag__url .sendbird-incoming-og-message__og-tag__url__label {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
        .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag .sendbird-incoming-og-message__og-tag__emoji-reactions--wrapper:focus {
          outline: none; }
        .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag .sendbird-incoming-og-message__og-tag__emoji-reactions--wrapper .sendbird-incoming-og-message__og-tag__emoji-reactions {
          margin: 8px -12px -12px -12px; }
          .sendbird-theme--light .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag .sendbird-incoming-og-message__og-tag__emoji-reactions--wrapper .sendbird-incoming-og-message__og-tag__emoji-reactions {
            border: 1px solid #F0F0F0; }
          .sendbird-theme--dark .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag .sendbird-incoming-og-message__og-tag__emoji-reactions--wrapper .sendbird-incoming-og-message__og-tag__emoji-reactions {
            border: 1px solid #2C2C2C; }
          .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag .sendbird-incoming-og-message__og-tag__emoji-reactions--wrapper .sendbird-incoming-og-message__og-tag__emoji-reactions:hover {
            cursor: default; }
          .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--body .sendbird-incoming-og-message__og-tag .sendbird-incoming-og-message__og-tag__emoji-reactions--wrapper .sendbird-incoming-og-message__og-tag__emoji-reactions:focus {
            outline: none; }
    .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--right-padding {
      position: relative;
      width: 68px; }
      .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--right-padding .sendbird-incoming-og-message__sent-at {
        position: absolute;
        bottom: 4px;
        left: 4px; }
      .sendbird-incoming-og-message .sendbird-incoming-og-message--inner .sendbird-incoming-og-message--right-padding .sendbird-incoming-og-message__more {
        position: absolute;
        top: 18px;
        left: 4px;
        display: none; }

.sendbird-notification {
  margin-top: 8px;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 4px;
  padding: 0px 2px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .sendbird-theme--light .sendbird-notification {
    background-color: #7B53EF; }
  .sendbird-theme--dark .sendbird-notification {
    background-color: #9E8CF5; }
  .sendbird-notification:hover {
    cursor: pointer; }
    .sendbird-theme--light .sendbird-notification:hover {
      background-color: #6440C4; }
    .sendbird-theme--dark .sendbird-notification:hover {
      background-color: #7B53EF; }
  .sendbird-theme--light .sendbird-notification.sendbird-notification--frozen {
    background-color: #b2d9ff; }
  .sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen {
    background-color: #b2d9ff; }
  .sendbird-theme--light .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
    color: rgba(0, 0, 0, 0.88); }
  .sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
    color: rgba(0, 0, 0, 0.88); }
  .sendbird-notification .sendbird-notification__text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; }

.sendbird-conversation__connection-status {
  display: flex;
  align-items: center;
  padding-top: 5px; }

.sendbird-chat-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  min-height: 64px;
  padding-left: 20px;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis; }
  .sendbird-theme--light .sendbird-chat-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-chat-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #161616; }
  .sendbird-chat-header__left {
    margin-right: 24px; }
  .sendbird-chat-header__left, .sendbird-chat-header__right {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .sendbird-chat-header .sendbird-chat-header__title {
    margin-left: 8px;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 780px; }
  .sendbird-chat-header .sendbird-chat-header__subtitle {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .sendbird-chat-header .sendbird-chat-header__active {
    margin-right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 4px; }
    .sendbird-theme--light .sendbird-chat-header .sendbird-chat-header__active {
      background-color: #2EBA9F; }
    .sendbird-theme--dark .sendbird-chat-header .sendbird-chat-header__active {
      background-color: #6FD6BE; }
  .sendbird-chat-header .sendbird-chat-header__mute {
    margin-right: 26px; }
  .sendbird-chat-header .sendbird-iconbutton--pressed {
    background-color: inherit; }

.sendbird-channel-settings {
  height: 100%;
  width: 320px;
  display: inline-block; }
  .sendbird-theme--light .sendbird-channel-settings {
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-channel-settings {
    background-color: #161616;
    border: 1px solid rgba(255, 255, 255, 0.12); }
  .sendbird-channel-settings .sendbird-channel-settings__header-icon {
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 21px; }
    .sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__header-icon .sendbird-channel-settings__close-icon path {
      fill: rgba(0, 0, 0, 0.88); }
    .sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__header-icon .sendbird-channel-settings__close-icon path {
      fill: rgba(255, 255, 255, 0.88); }
  .sendbird-channel-settings .sendbird-channel-settings__members .sendbird-badge__text {
    margin-top: -2px; }
  .sendbird-channel-settings .sendbird-channel-settings__header {
    height: 64px;
    min-height: 64px;
    position: relative;
    padding: 20px 24px;
    box-sizing: border-box; }
    .sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__header {
      border-bottom: solid 1px rgba(0, 0, 0, 0.12); }
    .sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__header {
      border-bottom: solid 1px rgba(255, 255, 255, 0.12); }
  .sendbird-channel-settings .sendbird-channel-settings__scroll-area {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: calc(100% - 64px);
    overflow-y: auto; }
  .sendbird-channel-settings .sendbird-channel-settings__panel-item {
    cursor: pointer;
    position: relative;
    padding: 12px 22px 10px 56px; }
    .sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__panel-item {
      background-color: #FFFFFF;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    .sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__panel-item {
      background-color: #161616;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12); }
    .sendbird-channel-settings .sendbird-channel-settings__panel-item:focus {
      outline: none; }
    .sendbird-channel-settings .sendbird-channel-settings__panel-item.sendbird-channel-settings__panel-item__disabled {
      cursor: not-allowed; }
  .sendbird-channel-settings .sendbird-channel-settings__panel-icon-left,
  .sendbird-channel-settings .sendbird-channel-settings__panel-icon-right {
    position: absolute;
    top: 10px; }
  .sendbird-channel-settings .sendbird-channel-settings__panel-icon-left {
    left: 16px; }
  .sendbird-channel-settings .sendbird-channel-settings__panel-icon-right {
    right: 16px; }
  .sendbird-channel-settings .sendbird-channel-settings__panel-icon--open {
    transform: rotate(90deg); }
  .sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__panel-icon--chevron path {
    fill: rgba(0, 0, 0, 0.88); }
  .sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__panel-icon--chevron path {
    fill: rgba(255, 255, 255, 0.88); }
  .sendbird-theme--light .sendbird-channel-settings .sendbird-channel-settings__panel-icon__leave path {
    fill: #E53157; }
  .sendbird-theme--dark .sendbird-channel-settings .sendbird-channel-settings__panel-icon__leave path {
    fill: #E53157; }

.sendbird-channel-profile {
  position: relative;
  text-align: center;
  padding: 32px 40px 27px 40px; }
  .sendbird-theme--light .sendbird-channel-profile {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-channel-profile {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #161616; }
  .sendbird-channel-profile .sendbird-channel-profile--inner {
    height: 92px; }
  .sendbird-channel-profile .sendbird-channel-profile__avatar {
    margin-bottom: 16px;
    text-align: center; }
  .sendbird-channel-profile .sendbird-channel-profile__title {
    max-width: 240px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    height: 18px; }
  .sendbird-channel-profile .sendbird-channel-profile__edit {
    position: absolute;
    top: 10px;
    right: 16px; }

.channel-profile-form .channel-profile-form__img-section {
  position: relative; }

.channel-profile-form .channel-profile-form__avatar-button {
  position: absolute;
  top: 54px;
  left: 96px; }

.channel-profile-form .channel-profile-form__avatar {
  margin-top: 16px; }

.channel-profile-form .channel-profile-form__name-section {
  margin-top: 12px; }
  .channel-profile-form .channel-profile-form__name-section .sendbird-input {
    height: 40px; }

.sendbird-members-accordion {
  padding: 8px 16px 16px 16px;
  position: relative;
  box-sizing: border-box; }
  .sendbird-theme--light .sendbird-members-accordion {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-members-accordion {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #161616; }
  .sendbird-members-accordion .sendbird-members-accordion__member {
    position: relative;
    padding: 12px 0px 12px 44px;
    height: 40px;
    box-sizing: border-box; }
    .sendbird-members-accordion .sendbird-members-accordion__member .sendbird-label {
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .sendbird-members-accordion .sendbird-members-accordion__member-avatar {
    position: absolute;
    left: 0;
    top: 10px;
    cursor: pointer; }
  .sendbird-members-accordion .sendbird-members-accordion__footer {
    display: flex;
    justify-content: start;
    margin-top: 8px; }
    .sendbird-members-accordion .sendbird-members-accordion__footer .sendbird-members-accordion__footer__all-members {
      margin-right: 16px; }

.sendbird-more-members__popup-scroll {
  max-height: 420px;
  overflow-y: auto; }

.sendbird-channel-settings__operator .sendbird-channel-settings__accordion-icon {
  position: absolute;
  left: 14px; }

.sendbird-channel-settings__operator .sendbird-accordion__panel-heder {
  position: relative; }

.sendbird-channel-settings__empty-list {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 4px;
  display: block; }

.sendbird-channel-settings-accordion__footer {
  padding-top: 16px;
  display: flex;
  justify-content: left;
  padding-left: 12px; }
  .sendbird-channel-settings-accordion__footer .sendbird-button:nth-child(2) {
    margin-left: 8px; }

.sendbird-channel-settings__freeze {
  max-width: 320px;
  position: relative;
  padding: 12px 22px 10px 56px; }
  .sendbird-theme--light .sendbird-channel-settings__freeze {
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-channel-settings__freeze {
    background-color: #161616;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12); }

.sendbird-channel-settings__frozen-icon {
  position: absolute;
  right: 20px;
  top: 12px; }
  .sendbird-channel-settings__frozen-icon .sendbird-icon {
    cursor: pointer; }

.sendbird-channel-settings__members-list .sendbird-badge,
.sendbird-channel-settings__members .sendbird-badge {
  position: absolute;
  right: 44px;
  top: 12px; }
  .sendbird-theme--light .sendbird-channel-settings__members-list .sendbird-badge, .sendbird-theme--light
  .sendbird-channel-settings__members .sendbird-badge {
    background-color: #D9D9D9; }
  .sendbird-theme--dark .sendbird-channel-settings__members-list .sendbird-badge, .sendbird-theme--dark
  .sendbird-channel-settings__members .sendbird-badge {
    background-color: #393939; }
  .sendbird-theme--light .sendbird-channel-settings__members-list .sendbird-badge .sendbird-label, .sendbird-theme--light
  .sendbird-channel-settings__members .sendbird-badge .sendbird-label {
    color: rgba(0, 0, 0, 0.5); }
  .sendbird-theme--dark .sendbird-channel-settings__members-list .sendbird-badge .sendbird-label, .sendbird-theme--dark
  .sendbird-channel-settings__members .sendbird-badge .sendbird-label {
    color: rgba(255, 255, 255, 0.5); }

.sendbird-accordion {
  padding: 8px 0px 16px 0px;
  position: relative;
  box-sizing: border-box; }
  .sendbird-theme--light .sendbird-accordion {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-accordion {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #161616; }
  .sendbird-accordion .sendbird-accordion__member {
    position: relative;
    padding: 12px 0px 12px 44px;
    height: 40px;
    box-sizing: border-box; }
  .sendbird-accordion .sendbird-accordion__footer {
    display: flex;
    justify-content: start;
    margin-top: 8px; }

.sendbird-accordion__panel-heder {
  cursor: pointer;
  position: relative;
  padding: 12px 22px 10px 56px; }
  .sendbird-theme--light .sendbird-accordion__panel-heder {
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .sendbird-theme--dark .sendbird-accordion__panel-heder {
    background-color: #161616;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12); }
  .sendbird-accordion__panel-heder:focus {
    outline: none; }
  .sendbird-accordion__panel-heder.sendbird-accordion__panel-item__disabled {
    cursor: not-allowed; }

.sendbird-accordion__panel-icon-left,
.sendbird-accordion__panel-icon-right {
  position: absolute;
  top: 10px; }

.sendbird-accordion__panel-icon-left {
  left: 16px; }

.sendbird-accordion__panel-icon-right {
  right: 16px; }

.sendbird-accordion__panel-icon--open {
  transform: rotate(90deg); }

.sendbird-theme--light .sendbird-accordion__panel-icon--chevron path {
  fill: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-accordion__panel-icon--chevron path {
  fill: rgba(255, 255, 255, 0.88); }

.sendbird-theme--light .sendbird-accordion__panel-icon__leave path {
  fill: #E53157; }

.sendbird-theme--dark .sendbird-accordion__panel-icon__leave path {
  fill: #E53157; }

.sendbird-user-list-item--small {
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 40px; }
  .sendbird-user-list-item--small__avatar {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 12px; }
  .sendbird-user-list-item--small .sendbird-muted-avatar {
    position: absolute;
    top: 10px;
    left: 12px;
    z-index: 2;
    pointer-events: none; }
  .sendbird-user-list-item--small__title {
    position: absolute;
    top: 10px;
    left: 54px;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .sendbird-user-list-item--small__subtitle {
    position: absolute;
    top: 23px;
    left: 140px;
    max-width: 237px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .sendbird-user-list-item--small__checkbox {
    position: absolute;
    top: 16px;
    right: 16px; }
  .sendbird-user-list-item--small__action {
    display: none;
    position: absolute;
    right: 10px;
    top: 4px; }
  .sendbird-user-list-item--small__operator {
    position: absolute;
    right: 24px;
    top: 10px; }
  .sendbird-theme--light .sendbird-user-list-item--small:hover, .sendbird-theme--light .sendbird-user-list-item--small.sendbird-icon--pressed {
    background-color: #F0F0F0; }
  .sendbird-theme--dark .sendbird-user-list-item--small:hover, .sendbird-theme--dark .sendbird-user-list-item--small.sendbird-icon--pressed {
    background-color: #2C2C2C; }
  .sendbird-user-list-item--small:hover .sendbird-user-list-item--small__action, .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-user-list-item--small__action {
    display: block; }
  .sendbird-user-list-item--small:hover .sendbird-user-list-item--small__operator, .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-user-list-item--small__operator {
    display: none; }
  .sendbird-theme--light .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-iconbutton {
    background-color: #E2DFFF; }
  .sendbird-theme--dark .sendbird-user-list-item--small.sendbird-icon--pressed .sendbird-iconbutton {
    background-color: #000000; }

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
.sendbird-app__wrap {
  width: 100%;
  height: 100%;
  display: flex; }
  .sendbird-app__wrap .sendbird-app__conversation-wrap {
    flex: 1;
    position: relative; }
  .sendbird-app__wrap .sendbird-app__channellist-wrap {
    max-width: 320px; }
  .sendbird-theme--light .sendbird-app__wrap .sendbird-app__conversation--settings-open .sendbird-chat-header__info {
    background-color: #E2DFFF; }
  .sendbird-theme--dark .sendbird-app__wrap .sendbird-app__conversation--settings-open .sendbird-chat-header__info {
    background-color: #000000; }
  .sendbird-app__wrap .sendbird-channel-settings {
    border-left: none; }
    .sendbird-app__wrap .sendbird-channel-settings .sendbird-channel-settings__header {
      height: 65px; }

/*# sourceMappingURL=index.css.map */