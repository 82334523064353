.sendbird-theme--light .sendbird-color--onbackground-1 {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-color--onbackground-1 {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--light .sendbird-color--onbackground-1--background-color {
  background-color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--dark .sendbird-color--onbackground-1--background-color {
  background-color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--light .sendbird-color--onbackground-2 {
  color: rgba(0, 0, 0, 0.5); }

.sendbird-theme--dark .sendbird-color--onbackground-2 {
  color: rgba(255, 255, 255, 0.5); }

.sendbird-theme--light .sendbird-color--onbackground-2--background-color {
  background-color: rgba(0, 0, 0, 0.5); }

.sendbird-theme--dark .sendbird-color--onbackground-2--background-color {
  background-color: rgba(255, 255, 255, 0.5); }

.sendbird-theme--light .sendbird-color--onbackground-3 {
  color: rgba(0, 0, 0, 0.38); }

.sendbird-theme--dark .sendbird-color--onbackground-3 {
  color: rgba(255, 255, 255, 0.38); }

.sendbird-theme--light .sendbird-color--onbackground-3--background-color {
  background-color: rgba(0, 0, 0, 0.38); }

.sendbird-theme--dark .sendbird-color--onbackground-3--background-color {
  background-color: rgba(255, 255, 255, 0.38); }

.sendbird-theme--light .sendbird-color--onbackground-4 {
  color: rgba(0, 0, 0, 0.12); }

.sendbird-theme--dark .sendbird-color--onbackground-4 {
  color: rgba(255, 255, 255, 0.12); }

.sendbird-theme--light .sendbird-color--onbackground-4--background-color {
  background-color: rgba(0, 0, 0, 0.12); }

.sendbird-theme--dark .sendbird-color--onbackground-4--background-color {
  background-color: rgba(255, 255, 255, 0.12); }

.sendbird-theme--light .sendbird-color--oncontent-1 {
  color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--dark .sendbird-color--oncontent-1 {
  color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--light .sendbird-color--oncontent-1--background-color {
  background-color: rgba(255, 255, 255, 0.88); }

.sendbird-theme--dark .sendbird-color--oncontent-1--background-color {
  background-color: rgba(0, 0, 0, 0.88); }

.sendbird-theme--light .sendbird-color--primary {
  color: #7B53EF; }

.sendbird-theme--dark .sendbird-color--primary {
  color: #9E8CF5; }

.sendbird-theme--light .sendbird-color--primary--background-color {
  background-color: #7B53EF; }

.sendbird-theme--dark .sendbird-color--primary--background-color {
  background-color: #9E8CF5; }

.sendbird-theme--light .sendbird-color--error {
  color: #E53157; }

.sendbird-theme--dark .sendbird-color--error {
  color: #E53157; }

.sendbird-theme--light .sendbird-color--error--background-color {
  background-color: #E53157; }

.sendbird-theme--dark .sendbird-color--error--background-color {
  background-color: #E53157; }
