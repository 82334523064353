.sendbird-members-accordion {
  padding: 8px 16px 16px 16px;
  position: relative;
  box-sizing: border-box; }
  .sendbird-theme--light .sendbird-members-accordion {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-members-accordion {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #161616; }
  .sendbird-members-accordion .sendbird-members-accordion__member {
    position: relative;
    padding: 12px 0px 12px 44px;
    height: 40px;
    box-sizing: border-box; }
    .sendbird-members-accordion .sendbird-members-accordion__member .sendbird-label {
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
  .sendbird-members-accordion .sendbird-members-accordion__member-avatar {
    position: absolute;
    left: 0;
    top: 10px;
    cursor: pointer; }
  .sendbird-members-accordion .sendbird-members-accordion__footer {
    display: flex;
    justify-content: start;
    margin-top: 8px; }
    .sendbird-members-accordion .sendbird-members-accordion__footer .sendbird-members-accordion__footer__all-members {
      margin-right: 16px; }

.sendbird-more-members__popup-scroll {
  max-height: 420px;
  overflow-y: auto; }
