@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
.sendbird-app__wrap {
  width: 100%;
  height: 100%;
  display: flex; }
  .sendbird-app__wrap .sendbird-app__conversation-wrap {
    flex: 1;
    position: relative; }
  .sendbird-app__wrap .sendbird-app__channellist-wrap {
    max-width: 320px; }
  .sendbird-theme--light .sendbird-app__wrap .sendbird-app__conversation--settings-open .sendbird-chat-header__info {
    background-color: #E2DFFF; }
  .sendbird-theme--dark .sendbird-app__wrap .sendbird-app__conversation--settings-open .sendbird-chat-header__info {
    background-color: #000000; }
  .sendbird-app__wrap .sendbird-channel-settings {
    border-left: none; }
    .sendbird-app__wrap .sendbird-channel-settings .sendbird-channel-settings__header {
      height: 65px; }
