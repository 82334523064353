.sendbird-channel-profile {
  position: relative;
  text-align: center;
  padding: 32px 40px 27px 40px; }
  .sendbird-theme--light .sendbird-channel-profile {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF; }
  .sendbird-theme--dark .sendbird-channel-profile {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #161616; }
  .sendbird-channel-profile .sendbird-channel-profile--inner {
    height: 92px; }
  .sendbird-channel-profile .sendbird-channel-profile__avatar {
    margin-bottom: 16px;
    text-align: center; }
  .sendbird-channel-profile .sendbird-channel-profile__title {
    max-width: 240px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    height: 18px; }
  .sendbird-channel-profile .sendbird-channel-profile__edit {
    position: absolute;
    top: 10px;
    right: 16px; }

.channel-profile-form .channel-profile-form__img-section {
  position: relative; }

.channel-profile-form .channel-profile-form__avatar-button {
  position: absolute;
  top: 54px;
  left: 96px; }

.channel-profile-form .channel-profile-form__avatar {
  margin-top: 16px; }

.channel-profile-form .channel-profile-form__name-section {
  margin-top: 12px; }
  .channel-profile-form .channel-profile-form__name-section .sendbird-input {
    height: 40px; }
