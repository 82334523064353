.sendbird-notification {
  margin-top: 8px;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 4px;
  padding: 0px 2px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .sendbird-theme--light .sendbird-notification {
    background-color: #7B53EF; }
  .sendbird-theme--dark .sendbird-notification {
    background-color: #9E8CF5; }
  .sendbird-notification:hover {
    cursor: pointer; }
    .sendbird-theme--light .sendbird-notification:hover {
      background-color: #6440C4; }
    .sendbird-theme--dark .sendbird-notification:hover {
      background-color: #7B53EF; }
  .sendbird-theme--light .sendbird-notification.sendbird-notification--frozen {
    background-color: #b2d9ff; }
  .sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen {
    background-color: #b2d9ff; }
  .sendbird-theme--light .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
    color: rgba(0, 0, 0, 0.88); }
  .sendbird-theme--dark .sendbird-notification.sendbird-notification--frozen .sendbird-notification__text {
    color: rgba(0, 0, 0, 0.88); }
  .sendbird-notification .sendbird-notification__text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px; }
